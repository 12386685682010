import React from "react";
import TinyEditor from "./TinyEditor";

export type TypingContent = {
  content: string;
  htmlContent: string;
};

interface TinyBlankProps {
  onTyping: (content: string) => void;
  previousAnswer?: string;
  width?: string | number;
  height?: string | number;
}

interface TinyBlankState {
  content: string;
}

export default class TinyBlank extends React.Component<
  TinyBlankProps,
  TinyBlankState
> {
  static defaultProps = {
    width: "100%",
    height: "100%"
  };

  constructor(props: TinyBlankProps) {
    super(props);
    const { previousAnswer } = this.props;
    this.state = {
      content: previousAnswer || ""
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleEditorChange(content: string, ed: any): void {
    const { onTyping } = this.props;
    ed.getBody()
      .querySelectorAll("input")
      .forEach((el: any) => {
        el.addEventListener("input", (ev: any) => {
          const input = ev.target;
          input.setAttribute("value", input.value);
        });
      });
    onTyping(content);
    this.setState({ content });
  }

  render(): JSX.Element {
    const { content } = this.state;
    const { width, height } = this.props;
    return (
      <TinyEditor
        init={{
          height,
          width,
          setup(editor: any) {
            editor.on("keydown", (e: any) => {
              if (e.target.nodeName.toLowerCase() !== "input") {
                e.preventDefault();
              }
            });
          },
          menubar: false,
          toolbar: false
        }}
        value={content}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}
