import React from "react";
import {
  AUDIO_QUESTION,
  DISSERTATION_QUESTION,
  GRID_MULTIPLE_CHOICE_QUESTION,
  GRID_SINGLE_CHOICE_QUESTION,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MULTIPLE_CHOICE_QUESTION,
  SHORT_QUESTION,
  SINGLE_CHOICE_QUESTION,
  SPREADSHEET_QUESTION,
  TEXT_BLANKS_QUESTION,
  TEXT_DROPDOWN_QUESTION,
  TRAINING_SPREADSHEET,
  WIN
} from "../../../static/misc/constants";
import { guidGenerator } from "../../../static/misc/utils";
import {
  ChoiceTypeWithSelection,
  ExamQuestion,
  ExamType,
  ExamWorkBook
} from "../../modules/exams/types/exam";
import { StudentAnswerType } from "../../modules/examTaking/types/studentPaper";
import MultipleLineField from "../molecules/MultipleLineField";
import GridMultipleQuestion from "./GridMultipleQuestion";
import FillInBlank from "../molecules/FillInBlank";
import FillInSelector from "../molecules/FillInSelector";
import GridSingleQuestion from "./GridSingleQuestion";
import Qcm from "./Qcm";
import Qcu from "./Qcu";
import { MediaType } from "../../modules/exams/types/attachedfiles";
import SpreadSheet from "./SpreadSheet";
import trainingSpreadSheet from "../../../static/misc/training/files/spreadsheet";
import AudioExam from "./AudioExam";

export interface SwitcherQuestionsTypesProps {
  currentAttachedFiles: MediaType[] | undefined;
  previousAnswer: StudentAnswerType | undefined;
  currentAnswer: StudentAnswerType | undefined;
  item: ExamQuestion;
  onEndExam: boolean;
  shouldStopRecording: boolean;
  workBooks?: ExamWorkBook[];
  toggleAudioProctoring: () => void;
  onPushStudentAnswer: (studentAnswer: Partial<StudentAnswerType>) => void;
  onSaveFileOnFS: (
    questionId: string,
    questionType: string,
    fileContent?: string,
    mediaId?: string,
    saveSpreadsheetCb?: (filepath: string) => void
  ) => void;
  updateWorkBook: (fileId: string, content: string) => void;
  currentExam: ExamType;
}

class SwitcherQuestionsTypes extends React.Component<SwitcherQuestionsTypesProps> {
  componentDidMount(): void {
    const { item, onPushStudentAnswer, currentAnswer } = this.props;

    if (!currentAnswer) {
      onPushStudentAnswer({
        question:
          item.spreadsheetFile?.id === TRAINING_SPREADSHEET
            ? item.spreadsheetFile.id
            : item.id || "",
        questionType: item.type,
        givenChoices: [],
        givenAnswer: undefined,
        mediaObjects: [],
        mediaData: [],
        noChoice: undefined,
        status: "visited"
      });
    }
  }

  // We want the component to re-render only for the following reasons
  shouldComponentUpdate(nxtProps: SwitcherQuestionsTypesProps): boolean {
    const { currentAnswer, previousAnswer, item, onEndExam } = this.props;

    return (
      onEndExam !== nxtProps.onEndExam ||
      currentAnswer !== nxtProps.currentAnswer ||
      previousAnswer !== nxtProps.previousAnswer ||
      item !== nxtProps.item
    );
  }

  render(): JSX.Element | null {
    const {
      currentAnswer,
      currentAttachedFiles,
      onEndExam,
      item,
      previousAnswer,
      shouldStopRecording,
      workBooks,
      onPushStudentAnswer,
      onSaveFileOnFS,
      toggleAudioProctoring,
      updateWorkBook,
      currentExam
    } = this.props;
    switch (item.type) {
      case SPREADSHEET_QUESTION: {
        const spreadSheetContent =
          !IS_PREVIEW && !IS_WEB_ENABLE
            ? currentAttachedFiles?.find(
                (e) => e.mediaId === item.spreadsheetFile?.id
              )?.blobUrl
            : currentAttachedFiles?.find(
                (e) => e.mediaId === item.spreadsheetFile?.id
              )?.data;
        const workBook = workBooks?.find(
          (wb: ExamWorkBook) => wb.id === item.spreadsheetFile?.id
        );
        if (
          item.spreadsheetFile &&
          item.spreadsheetFile.id &&
          (spreadSheetContent ||
            item.spreadsheetFile.id === TRAINING_SPREADSHEET)
        ) {
          if (spreadSheetContent?.includes("blob:")) {
            return null;
          }

          return (
            <SpreadSheet
              key={guidGenerator()}
              currentExam={currentExam}
              id={item.spreadsheetFile.id}
              questionId={item.id || ""}
              isTraining={item.spreadsheetFile.id === TRAINING_SPREADSHEET}
              onPushStudentAnswer={onPushStudentAnswer}
              forceSaveSpreadSheet={onEndExam}
              content={
                item.spreadsheetFile.id !== TRAINING_SPREADSHEET
                  ? workBook?.content ?? (spreadSheetContent as string)
                  : trainingSpreadSheet
              }
              saveFile={(cb: (filepath: string) => void) => {
                if (
                  !IS_PREVIEW &&
                  item.spreadsheetFile &&
                  item.spreadsheetFile.id !== TRAINING_SPREADSHEET
                ) {
                  onSaveFileOnFS(
                    item.id as string,
                    item.type,
                    undefined,
                    item.spreadsheetFile.id,
                    cb
                  );
                }
              }}
              updateWorkBook={(content: string) => {
                if (
                  !IS_PREVIEW &&
                  item.spreadsheetFile &&
                  item.spreadsheetFile.id !== TRAINING_SPREADSHEET
                ) {
                  updateWorkBook(item.spreadsheetFile.id, content);
                }
              }}
            />
          );
        }
        return null;
      }
      case MULTIPLE_CHOICE_QUESTION: {
        return (
          <Qcm
            key={guidGenerator()}
            previousAnswer={previousAnswer?.givenChoices}
            givenChoices={currentAnswer?.givenChoices}
            items={item.choices}
            itemsSelected={(selection) => {
              const choices = selection
                .filter((choiceSelection) =>
                  choiceSelection.isSelected ? choiceSelection.name : undefined
                )
                .map((mappedItem: ChoiceTypeWithSelection) =>
                  mappedItem.id ? mappedItem.id : ""
                );
              onPushStudentAnswer({
                question: item.id ? item.id : "",
                questionType: item.type,
                givenChoices: choices
              });
            }}
          />
        );
      }
      case TEXT_DROPDOWN_QUESTION: {
        return (
          <FillInSelector
            question={item.text}
            previousAnswer={previousAnswer?.givenAnswer}
            onGetAnswer={(answer) => {
              onPushStudentAnswer({
                question: item.id ? item.id : "",
                questionType: item.type,
                givenAnswer: answer
              });
            }}
          />
        );
      }
      case TEXT_BLANKS_QUESTION: {
        return (
          <FillInBlank
            question={item.text}
            previousAnswer={previousAnswer?.givenAnswer}
            onGetAnswer={(answer) => {
              onPushStudentAnswer({
                question: item.id ? item.id : "",
                questionType: item.type,
                givenAnswer: answer
              });
            }}
          />
        );
      }
      case SINGLE_CHOICE_QUESTION: {
        return (
          <Qcu
            key={guidGenerator()}
            previousAnswer={previousAnswer?.givenChoices}
            givenChoices={currentAnswer?.givenChoices}
            items={item.choices}
            itemSelected={(selection) => {
              onPushStudentAnswer({
                question: item.id ? item.id : "",
                questionType: item.type,
                givenChoices: selection && selection !== "" ? [selection] : []
              });
            }}
          />
        );
      }
      case GRID_SINGLE_CHOICE_QUESTION: {
        if (item.columnLabels && item.rowLabels && item.choices)
          return (
            <GridSingleQuestion
              key={guidGenerator()}
              givenChoices={currentAnswer?.givenChoices}
              previousAnswer={previousAnswer?.givenChoices}
              columnLabels={item.columnLabels}
              rowLabels={item.rowLabels}
              choices={item.choices}
              changeSelection={(rowItems, choices, columnLab) => {
                // Iterating on rows
                const idChoices = rowItems.map(
                  (gridColumn, gridColumnIndex) => {
                    // Iterating on columns in rows
                    const columnChoices = gridColumn.map(
                      (_gridRow, gridRowIndex) => {
                        // Matching position of selected choice vs available to choices to get the id of the choice
                        const rowChoices = choices.find(
                          (choice) =>
                            rowItems[gridColumnIndex][gridRowIndex]
                              .isSelected === true &&
                            choice.columnIndex ===
                              columnLab[gridColumnIndex].index &&
                            choice.rowIndex ===
                              rowItems[gridColumnIndex][gridRowIndex].index
                        );
                        return rowChoices?.id ? rowChoices.id : null;
                      }
                    );
                    const studentChoicesId = columnChoices.find(
                      (choice) => choice !== null
                    );
                    return studentChoicesId;
                  }
                );
                const finalIdChoices = idChoices.filter(
                  (idChoice) => idChoice !== undefined
                ) as string[];
                onPushStudentAnswer({
                  question: item.id ? item.id : "",
                  questionType: item.type,
                  givenChoices: finalIdChoices
                });
              }}
            />
          );
        return null;
      }
      case GRID_MULTIPLE_CHOICE_QUESTION: {
        if (item.columnLabels && item.rowLabels && item.choices)
          return (
            <GridMultipleQuestion
              key={guidGenerator()}
              givenChoices={currentAnswer?.givenChoices}
              previousAnswer={previousAnswer?.givenChoices}
              columnLabels={item.columnLabels}
              rowLabels={item.rowLabels}
              choices={item.choices}
              changeSelection={(rowItems, choices, columnLab) => {
                // Iterating on rows
                const idChoices = rowItems.map(
                  (gridColumn, gridColumnIndex) => {
                    // Iterating on columns
                    const columnChoices = gridColumn.map(
                      (_gridRow, gridRowIndex) => {
                        // Matching position of selected choice vs available to choices to get the id of the choice
                        const rowChoices = choices.find(
                          (choice) =>
                            rowItems[gridColumnIndex][gridRowIndex]
                              .isSelected === true &&
                            choice.columnIndex ===
                              columnLab[gridColumnIndex].index &&
                            choice.rowIndex ===
                              rowItems[gridColumnIndex][gridRowIndex].index
                        );
                        return rowChoices?.id ? rowChoices.id : null;
                      }
                    );
                    const studentChoicesId = columnChoices.filter(
                      (choice) => choice !== null
                    );
                    return studentChoicesId;
                  }
                );
                const finalIdChoices = idChoices.flat() as string[];
                onPushStudentAnswer({
                  question: item.id ? item.id : "",
                  questionType: item.type,
                  givenChoices: finalIdChoices
                });
              }}
            />
          );
        return null;
      }
      case SHORT_QUESTION: {
        return (
          <MultipleLineField
            key={guidGenerator()}
            nbCharMax={
              item.characterLimitParam
                ? item.characterLimitParamValue
                : undefined
            }
            nbWordsMax={
              item.wordLimitParam ? item.wordLimitParamValue : undefined
            }
            previousAnswer={previousAnswer?.givenAnswer}
            onTyping={(answer) => {
              onPushStudentAnswer({
                question: item.id ? item.id : "",
                questionType: item.type,
                givenAnswer: answer.htmlContent
              });
            }}
          />
        );
      }
      case DISSERTATION_QUESTION: {
        return (
          <MultipleLineField
            key={guidGenerator()}
            nbCharMax={
              item.characterLimitParam
                ? item.characterLimitParamValue
                : undefined
            }
            nbWordsMax={
              item.wordLimitParam ? item.wordLimitParamValue : undefined
            }
            previousAnswer={previousAnswer?.givenAnswer}
            width="100%"
            height={WIN.height * 0.65}
            onTyping={(answer) => {
              onPushStudentAnswer({
                question: item.id ? item.id : "",
                questionType: item.type,
                givenAnswer: answer.htmlContent
              });
            }}
          />
        );
      }
      case AUDIO_QUESTION: {
        const alreadyAnswered =
          previousAnswer?.mediaObjects &&
          previousAnswer?.mediaObjects.length > 0;
        return (
          <AudioExam
            disabled={alreadyAnswered}
            key={guidGenerator()}
            maxRecordingTime={
              item.maxDurationRecordParam
                ? item.maxDurationRecordParamValue
                : undefined
            }
            startRecordTime={
              item.autoStartRecordDurationParam
                ? item.autoStartRecordDurationParamValue
                : undefined
            }
            toggleAudioProctoring={toggleAudioProctoring}
            shouldStopRecording={shouldStopRecording}
            getBlob={(blobContent: string | Blob) => {
              onSaveFileOnFS(
                item.id as string,
                item.type,
                blobContent as string
              );
            }}
          />
        );
      }
      default:
        return null;
    }
  }
}

export default SwitcherQuestionsTypes;
