import i18n from "i18n-js";

const fr = require("./languages/fr.json");
const en = require("./languages/en.json");
const it = require("./languages/it.json");
const pt = require("./languages/pt.json");
const es = require("./languages/es.json");

i18n.translations = {
  en,
  it,
  pt,
  es,
  fr
};
// for web only TODO : handle on mobile devices (not XPLATFORM)
// checking length to reduce to the first 2 letters in case it would be "en-us" or "en_US" format
if (
  window.navigator.language &&
  window.navigator.language.length >= 2 &&
  Object.keys(i18n.translations).includes(
    window.navigator.language.substring(0, 2)
  )
) {
  i18n.locale = window.navigator.language.substring(0, 2);
} else {
  i18n.locale = "en";
}

i18n.fallbacks = true;

export default i18n;
