import React from "react";
import _ from "lodash";

import { ExamType } from "../../modules/exams/types/exam";
import { StudentAnswerType } from "../../modules/examTaking/types/studentPaper";
import CustomCircularProgressbar from "../atoms/CustomCircularProgressbar";
import i18n from "../../services/i18n";
import { COLOR_GREY_EXAM_NAVBAR_COLOR_CODE } from "../../../static/misc/colors";

interface ExamProgressCircularProps {
  currentExam: ExamType;
  studentAnswers: StudentAnswerType[];
}

interface ExamProgressCircularState {
  questionsCount: {
    total: number;
    answered: number;
    percentage: number;
  };
}

class ExamProgressCircular extends React.Component<
  ExamProgressCircularProps,
  ExamProgressCircularState
> {
  constructor(props: ExamProgressCircularProps) {
    super(props);

    this.state = {
      questionsCount: {
        total: 0,
        answered: 0,
        percentage: 0
      }
    };
  }

  componentDidMount(): void {
    this.setQuestionsCount();
  }

  componentDidUpdate(prevProps: ExamProgressCircularProps): void {
    const { studentAnswers, currentExam } = this.props;

    if (
      !_.isEqual(prevProps.studentAnswers, studentAnswers) ||
      prevProps.currentExam !== currentExam
    ) {
      this.setQuestionsCount();
    }
  }

  componentWillUnmount(): void {
    this.setQuestionsCount(true);
  }

  getTotalCount(): number {
    const { currentExam } = this.props;
    const defaultValue = 0;

    if (typeof currentExam.examParts === "string" || !currentExam.examParts)
      return defaultValue;

    return currentExam.examParts?.reduce<number>((acc, ep) => {
      ep.partIndexes.forEach((pi) => {
        // eslint-disable-next-line no-param-reassign
        if (pi.type === "question") acc++;

        if (pi.type === "exercise" && pi.exercise) {
          const { questions } = pi.exercise;

          // eslint-disable-next-line no-param-reassign
          if (questions) acc += questions.length;
        }
      });

      return acc;
    }, defaultValue);
  }

  getAnsweredCount(): number {
    const { studentAnswers } = this.props;
    return (
      [...studentAnswers].filter((a) => a.status === "completed")?.length || 0
    );
  }

  setQuestionsCount(reset = false): void {
    this.setState({
      questionsCount: {
        total: reset ? 0 : this.getTotalCount(),
        answered: reset ? 0 : this.getAnsweredCount(),
        percentage: reset ? 0 : this.getPercentage()
      }
    });
  }

  getPercentage = (): number =>
    Math.round((this.getAnsweredCount() / this.getTotalCount()) * 100);

  render(): JSX.Element {
    const { questionsCount } = this.state;
    const { answered, total, percentage } = questionsCount;

    return (
      <CustomCircularProgressbar
        tooltip={i18n.t("examNavbar.examProgressCircularTooltip", {
          answered,
          total,
          percentage
        })}
        percentage={percentage}
        containerStyle={{
          width: 28,
          height: 28,
          paddingBottom: 3,
          borderBottom: `1px solid ${COLOR_GREY_EXAM_NAVBAR_COLOR_CODE}`
        }}
      />
    );
  }
}

export default ExamProgressCircular;
