import React, { ReactNode } from "react";
import Tippy from "@tippyjs/react";
import { Text, View, StyleSheet } from "react-native";
import {
  COLOR_BLUE_TESTWE,
  COLOR_SHADOW,
  COLOR_WHITE_70
} from "../../../static/misc/colors";
import {
  FONT_GILROY_BOLD,
  FONT_GILROY_REGULAR
} from "../../../static/misc/constants";

interface TooltipProps {
  placement?: string;
  delay?: number;
  bold?: boolean;
  contentText?: string;
  content?: JSX.Element;
  children: ReactNode;
  tooltipViewStyle?: any;
  disabled?: boolean;
}

class Tooltip extends React.PureComponent<TooltipProps> {
  render(): JSX.Element {
    const {
      contentText,
      children,
      placement = "bottom",
      delay = 100,
      bold = true,
      tooltipViewStyle,
      disabled,
      content
    } = this.props;
    return (
      <Tippy
        delay={delay}
        placement={placement}
        disabled={disabled}
        content={
          <View style={[styles.tooltipView, tooltipViewStyle]}>
            {content ?? (
              <Text style={bold ? styles.tooltipBoldText : styles.tooltipText}>
                {contentText}
              </Text>
            )}
          </View>
        }
      >
        <View>{children}</View>
      </Tippy>
    );
  }
}

const styles = StyleSheet.create({
  tooltipView: {
    backgroundColor: COLOR_WHITE_70,
    borderRadius: 5,
    padding: 8,
    shadowColor: COLOR_SHADOW,
    shadowOffset: {
      width: 0,
      height: 0
    }
  },
  tooltipBoldText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_BOLD
  },
  tooltipText: {
    color: COLOR_BLUE_TESTWE,
    fontFamily: FONT_GILROY_REGULAR
  }
});

export default Tooltip;
