import React from "react";
import moment from "moment";
import {
  Image,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  AreaHighlight,
  Popup,
  IHighlight,
  Content,
  NewHighlight
} from "react-pdf-highlighter";
import { ipcRenderer, shell } from "electron";
import {
  FONTSIZE_14,
  FONT_GILROY_SEMI_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";
import {
  COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
  COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND
} from "../../../static/misc/colors";
import i18n from "../../services/i18n";
import {
  download,
  fullscreenInBlue,
  fullscreenOutBlue,
  highlighter
} from "../../../static/misc/images";
import PdfViewerSideBar from "./PdfViewerSideBar";
import PdfViewerTip from "./PdfViewerTip";
import PdfHighlightPopup from "./PdfHighlightPopup";
import PdfViewerExplanationModal from "./PdfViewerExplanationModal";

export interface PdfViewerProps {
  canDownload?: boolean;
  isOpenBook: boolean;
  base64pdf?: string;
  blobUrl?: string;
  pdfName?: string;
  height: number;
  width: number;
  disableNavBar?: boolean;
  highlights?: Array<IHighlight>;
  isFullscreen: boolean;
  currentPdfPage?: number;
  onHighlightAdded?: (highlight: NewHighlight) => void;
  onHighlightRemoved?: (index: number) => void;
  onHighlightModified?: (highlight: NewHighlight) => void;
  onFullscreenToggle: () => void;
  onPdfPageChange?: (newPage: number) => void;
  withHighlightsMode?: boolean;
  withFullscreenMode?: boolean;
}

interface PdfViewerState {
  initViewerPage: boolean;
  scale: string | number;
  numPagesTotal: number;
  currentPage: number;
  document: Element | null;
  highlights: Array<IHighlight>;
  navbarHeight: number;
  selectedHighlight?: IHighlight;
  showExplanationPopup: boolean;
  isActiveViewer: boolean;
}

class PdfViewer extends React.Component<PdfViewerProps, PdfViewerState> {
  static defaultProps = {
    disableNavBar: false,
    withHighlightsMode: true,
    withFullscreenMode: true
  };

  pdfViewer?: PdfHighlighter<IHighlight>;

  initialScale = -1;

  constructor(props: PdfViewerProps) {
    super(props);
    this.state = {
      initViewerPage: true,
      scale: "auto",
      numPagesTotal: 0,
      currentPage: 1,
      document: null,
      highlights: props.highlights || [],
      navbarHeight: 0,
      showExplanationPopup: false,
      isActiveViewer: true
    };

    this.handlePdfLinkClick = this.handlePdfLinkClick.bind(this);
  }

  componentDidMount(): void {
    // Retrieving the document element from the DOM and assigning it to the state
    const el = document.getElementsByClassName("react-pdf__Document");
    if (el.length > 0) {
      this.setState({
        document: el[0]
      });
    }
  }

  shouldComponentUpdate(nextProps: PdfViewerProps, nextState: PdfViewerState) {
    return (
      !_.isEqual(this.state, nextState) || !_.isEqual(this.props, nextProps)
    );
  }

  componentDidUpdate(
    prevProps: PdfViewerProps,
    prevState: PdfViewerState
  ): void {
    const { highlights: propsHighlights, currentPdfPage } = this.props;
    const {
      document,
      highlights: stateHighlights,
      scale,
      isActiveViewer
    } = this.state;

    // if the document has been rendered and in the state, we assign the event listener to handle links clicks
    if (document !== prevState.document && document !== null) {
      document.addEventListener("click", this.handlePdfLinkClick);
    }

    if (currentPdfPage && currentPdfPage !== prevProps.currentPdfPage) {
      this.setState({
        currentPage: currentPdfPage
      });
      if (!isActiveViewer) {
        (this.pdfViewer?.viewer as any).currentPageNumber = currentPdfPage;
      }
    }
    if (scale !== prevState.scale) {
      if (this.pdfViewer?.viewer) {
        (this.pdfViewer?.viewer as any).currentScaleValue = scale;
      }
    }

    if (prevProps.highlights !== stateHighlights) {
      this.setState({
        highlights: stateHighlights ?? []
      });
    }

    if (prevProps.highlights !== propsHighlights) {
      this.setState({
        highlights: propsHighlights ?? []
      });
    }
  }

  componentWillUnmount(): void {
    const { document } = this.state;
    // Removing the event listener when the component is unmounting
    document?.removeEventListener("click", this.handlePdfLinkClick);
  }

  handlePdfLinkClick(e: Event): void {
    const { isOpenBook } = this.props;

    // Preventing default event
    e.preventDefault();

    const elemnt = e.target as HTMLElement;
    // Checking if the click was done on a hyperlink element
    if (elemnt.tagName.toLowerCase() === "a") {
      const href = elemnt.getAttribute("href");
      // Checking if the link is an http(s) link - we don't want to handle mailto or any other type of link
      if (href?.startsWith("http") && isOpenBook) {
        shell.openExternal(href);
      }
    }
  }

  handleDownload(): void {
    const { base64pdf, pdfName } = this.props;
    if (base64pdf) {
      ipcRenderer.send("DOWNLOAD_FILE", {
        base64: base64pdf,
        fileName: pdfName || `testwe-${moment()}-pdfDownload`
      });
    }
  }

  onNavbarLayout(event: LayoutChangeEvent): void {
    const { height } = event.nativeEvent.layout;
    this.setState({
      navbarHeight: height
    });
  }

  setPdfViewerRef(elm: PdfHighlighter<IHighlight> | null): void {
    const { onPdfPageChange, currentPdfPage } = this.props;
    const { initViewerPage } = this.state;
    if (!elm) return;

    this.pdfViewer = elm;

    const pdfViewer = this.pdfViewer?.viewer as any;

    if (initViewerPage) {
      /* eslint-disable no-underscore-dangle */
      pdfViewer._currentPageNumber = currentPdfPage;
      this.setState({ initViewerPage: false });
    }

    pdfViewer.container.addEventListener(
      "scroll",
      () => {
        const pageNumber = pdfViewer.currentPageNumber;
        // eslint-disable-next-line react/destructuring-assignment
        if (pageNumber !== this.state.currentPage) {
          if (onPdfPageChange) {
            onPdfPageChange(pageNumber);
          }
          this.setState({
            currentPage: pageNumber
          });
        }
      },
      false
    );

    setTimeout(() => {
      const currentScale = pdfViewer?._currentScale;
      if (currentScale && this.initialScale === -1) {
        this.initialScale = currentScale;
        this.setState({
          scale: currentScale
        });
      }
    }, 3000);
  }

  setSelectedHighlight(highlight?: IHighlight): void {
    this.setState({
      selectedHighlight: highlight
    });
  }

  scrollViewerTo = (highlight: IHighlight): void => {
    ipcRenderer.send("LOG_INFO", `PDF_VIEWER | scroll-to: ${highlight.id}`);
  };

  addHighlight(highlight: NewHighlight): void {
    const { onHighlightAdded } = this.props;
    const { highlights } = this.state;

    const newHighlight = { ...highlight, id: String(Math.random()).slice(2) };

    ipcRenderer.send(
      "LOG_INFO",
      `PDF_VIEWER | add-highlight: ${newHighlight.id}`
    );

    this.setState({
      highlights: [newHighlight, ...highlights]
    });

    if (onHighlightAdded) onHighlightAdded(newHighlight);
  }

  modifyHighlightComment(index: number, comment: string): void {
    const { onHighlightModified } = this.props;
    const { highlights } = this.state;

    const highlight = highlights[index];

    highlight.comment.text = comment;

    ipcRenderer.send(
      "LOG_INFO",
      `PDF_VIEWER | modify-highlight: ${highlight.id}`
    );

    if (onHighlightModified) onHighlightModified(highlight);

    this.setState({
      highlights: [...highlights]
    });
  }

  removeHighlight(index: number): void {
    const { onHighlightRemoved } = this.props;
    const { highlights } = this.state;

    highlights.splice(index, 1);

    ipcRenderer.send("LOG_INFO", `PDF_VIEWER | remove-highlight: ${index}`);

    if (onHighlightRemoved) onHighlightRemoved(index);

    if (onHighlightRemoved)
      this.setState({
        highlights: [...highlights]
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateHighlight(highlightId: string, position: any, content: Content): void {
    const { highlights } = this.state;

    ipcRenderer.send(
      "LOG_INFO",
      `PDF_VIEWER | update-highlight: ${highlightId}`
    );

    this.setState({
      highlights: highlights.map((highlight) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = highlight;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest
            }
          : highlight;
      })
    });
  }

  render(): JSX.Element {
    const {
      height,
      blobUrl,
      width,
      disableNavBar,
      canDownload,
      base64pdf,
      isFullscreen,
      onFullscreenToggle,
      onPdfPageChange,
      withHighlightsMode,
      withFullscreenMode
    } = this.props;
    const {
      currentPage,
      highlights,
      numPagesTotal,
      scale,
      navbarHeight,
      selectedHighlight,
      showExplanationPopup
    } = this.state;
    return (
      <View style={styles.navBar}>
        <PdfViewerExplanationModal
          isVisible={showExplanationPopup}
          onClose={() =>
            this.setState({
              showExplanationPopup: false
            })
          }
        />
        {withHighlightsMode && !disableNavBar && highlights.length > 0 && (
          <View style={{ width: width / 5, height }}>
            <PdfViewerSideBar
              selectedHighlight={selectedHighlight}
              highlights={highlights}
              highlightSelected={(index: number) => {
                const newSelectedHighlight = highlights[index];
                this.setSelectedHighlight(newSelectedHighlight);
                this.scrollViewerTo(newSelectedHighlight);
              }}
              modifyHighlightComment={(index: number, comment: string) =>
                this.modifyHighlightComment(index, comment)
              }
              removeHighlight={(index: number) => this.removeHighlight(index)}
            />
          </View>
        )}
        <View
          style={
            !withHighlightsMode && {
              width: "100%",
              height: "100%"
            }
          }
        >
          {!disableNavBar && (
            <View
              style={[
                styles.pdfBar,
                { height: height * 0.03 },
                isFullscreen && withHighlightsMode && { width: "80vw" }
              ]}
              onLayout={(event: LayoutChangeEvent) =>
                this.onNavbarLayout(event)
              }
            >
              {canDownload && base64pdf && (
                <TouchableOpacity onPress={() => this.handleDownload()}>
                  <Image
                    source={download}
                    style={[
                      styles.downloadIcon,
                      { tintColor: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND }
                    ]}
                  />
                </TouchableOpacity>
              )}
              {(currentPage > 1 && (
                <TouchableOpacity
                  onPress={() => {
                    this.setState((prevState) => {
                      const nextPage = prevState.currentPage - 1;
                      (this.pdfViewer
                        ?.viewer as any).currentPageNumber = nextPage;
                      ipcRenderer.send(
                        "LOG_INFO",
                        `PDF_VIEWER | previous-page ${nextPage}`
                      );
                      if (onPdfPageChange) {
                        onPdfPageChange(nextPage);
                      }
                      return {
                        currentPage: nextPage
                      };
                    });
                  }}
                >
                  <Text style={styles.pdfText}>{"<"}</Text>
                </TouchableOpacity>
              )) || <View />}
              <Text style={styles.pdfText}>{`${i18n.t(
                "media.nbPages"
              )} ${currentPage}/${numPagesTotal}`}</Text>
              {this.initialScale !== -1 && (
                <View style={{ flexDirection: "row" }}>
                  {scale > this.initialScale && (
                    <TouchableOpacity
                      onPress={() => {
                        ipcRenderer.send("LOG_INFO", `PDF_VIEWER | zoomOut`);
                        this.setState((prevState) => ({
                          scale: (prevState.scale as number) - 0.3
                        }));
                      }}
                    >
                      <Text style={styles.pdfText}>-</Text>
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      ipcRenderer.send("LOG_INFO", `PDF_VIEWER | zoomIn`);
                      this.setState((prevState) => ({
                        scale: (prevState.scale as number) + 0.3
                      }));
                    }}
                    style={{ marginStart: PADDING_SIDES * 0.3 }}
                  >
                    <Text style={styles.pdfText}>+</Text>
                  </TouchableOpacity>
                </View>
              )}
              <View style={{ flexDirection: "row" }}>
                {withHighlightsMode && (
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        showExplanationPopup: true
                      })
                    }
                  >
                    <Image
                      source={highlighter}
                      style={[
                        styles.downloadIcon,
                        { tintColor: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND }
                      ]}
                    />
                  </TouchableOpacity>
                )}
                {withFullscreenMode && (
                  <TouchableOpacity
                    onPress={() => {
                      onFullscreenToggle();
                      this.setState({ isActiveViewer: false });
                    }}
                    style={{ marginStart: PADDING_SIDES * 0.3 }}
                  >
                    <Image
                      source={
                        isFullscreen ? fullscreenOutBlue : fullscreenInBlue
                      }
                      style={[
                        styles.downloadIcon,
                        { tintColor: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND }
                      ]}
                    />
                  </TouchableOpacity>
                )}
                {currentPage < numPagesTotal && (
                  <TouchableOpacity
                    onPress={() =>
                      this.setState((prevState) => {
                        const nextPage = prevState.currentPage + 1;
                        (this.pdfViewer
                          ?.viewer as any).currentPageNumber = nextPage;
                        ipcRenderer.send(
                          "LOG_INFO",
                          `PDF_VIEWER | next-page ${nextPage}`
                        );
                        if (onPdfPageChange) {
                          onPdfPageChange(nextPage);
                        }
                        return {
                          currentPage: nextPage
                        };
                      })
                    }
                    style={{ marginStart: PADDING_SIDES * 0.3 }}
                  >
                    <Text style={styles.pdfText}>{">"}</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}

          {blobUrl ? (
            <View
              style={[
                {
                  width: disableNavBar ? width : (width * 4) / 5,
                  height: disableNavBar ? height : height - navbarHeight
                },
                !withHighlightsMode && { width: "100%" },
                isFullscreen &&
                  withHighlightsMode &&
                  withFullscreenMode && { width: "80vw" }
              ]}
            >
              <PdfLoader
                url={blobUrl}
                beforeLoad={<Text>{i18n.translate("media.loading")}</Text>}
              >
                {(pdfDocument) => {
                  setTimeout(() =>
                    this.setState({
                      numPagesTotal: pdfDocument.numPages
                    })
                  );

                  return (
                    <PdfHighlighter
                      pdfScaleValue={scale.toString()}
                      ref={(elm) => this.setPdfViewerRef(elm)}
                      pdfDocument={pdfDocument}
                      highlightTransform={(
                        highlight,
                        index,
                        setTip,
                        hideTip,
                        viewportToScaled,
                        screenshot,
                        isScrolledTo
                      ) => {
                        const isTextHighlight = !(
                          highlight.content && highlight.content.image
                        );

                        const component = isTextHighlight ? (
                          <Highlight
                            isScrolledTo={isScrolledTo}
                            position={highlight.position}
                            comment={highlight.comment}
                          />
                        ) : (
                          <AreaHighlight
                            isScrolledTo={isScrolledTo}
                            highlight={highlight}
                            onChange={(boundingRect) => {
                              this.updateHighlight(
                                highlight.id,
                                {
                                  boundingRect: viewportToScaled(boundingRect)
                                },
                                { image: screenshot(boundingRect) }
                              );
                            }}
                          />
                        );

                        return (
                          <Popup
                            popupContent={
                              <PdfHighlightPopup comment={highlight.comment} />
                            }
                            onMouseOver={(popupContent) =>
                              setTip(highlight, () => popupContent)
                            }
                            onMouseOut={hideTip}
                            key={index}
                          >
                            {component}
                          </Popup>
                        );
                      }}
                      enableAreaSelection={(event) => event.altKey}
                      highlights={highlights}
                      onScrollChange={() => {
                        this.setSelectedHighlight();
                      }}
                      scrollRef={(scrollTo) => {
                        this.scrollViewerTo = scrollTo;
                      }}
                      onSelectionFinished={(
                        position,
                        content,
                        hideTipAndSelection,
                        transformSelection
                      ) =>
                        withHighlightsMode ? (
                          <PdfViewerTip
                            onOpen={transformSelection}
                            onConfirm={(text) => {
                              this.addHighlight({
                                content,
                                position,
                                comment: { text, emoji: "" }
                              });

                              hideTipAndSelection();
                            }}
                          />
                        ) : (
                          <></>
                        )
                      }
                    />
                  );
                }}
              </PdfLoader>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  navBar: {
    flexDirection: "row"
  },
  pdfBar: {
    flexDirection: "row",
    paddingHorizontal: PADDING_SIDES / 2,
    paddingVertical: PADDING_SIDES * 0.25,
    justifyContent: "space-between",
    backgroundColor: COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND,
    top: 0,
    alignItems: "center"
  },
  pdfText: {
    fontFamily: FONT_GILROY_SEMI_BOLD,
    fontSize: FONTSIZE_14,
    color: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND
  },
  downloadIcon: {
    color: COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND,
    width: 15,
    height: 15
  }
});

export default PdfViewer;
