// BLACK
export const COLOR_BLACK = "#000000";

// WHITE
export const COLOR_WHITE = "#ffffff";
export const COLOR_WHITE_70 = "rgba(255, 255, 255, 0.7)";
export const WHITE_BACKGROUND = "#F2F2F2";

// BLUE
export const COLOR_BLUE_TESTWE = "#1d39c4";
export const COLOR_BLUE_TESTWE_50 = "rgba(29, 57, 196, 0.5)";
export const COLOR_BLUE_TESTWE_30 = "rgba(29, 57, 196, 0.3)";
export const COLOR_BLUE_TESTWE_50_FLAT = "rgb(167.5,181.5,251)";
export const COLOR_BLUE = "#03148c";
export const COLOR_BLUE_PLACEHOLDER = "#0f00a6";
export const COLOR_BLUE_LIGHT = "#759aff";
export const COLOR_BLUE_LIGHTER = "#f6f8fd";
export const COLOR_BLUE_HR = "rgba(79, 112, 201, 0.3)";
export const COLOR_BLUE_STEPPER = "#d6e1ff";

// RED
export const COLOR_RED = "#e2284a";
export const COLOR_RED_50 = "rgba(226, 40, 74, 0.5)";
export const COLOR_RED_25 = "rgba(226, 40, 74, 0.25)";

// GREEN
export const COLOR_GREEN_VALID = "#36b37e";
export const COLOR_GREEN_STEPPER = "#c3e8d8";

// YELLOW
export const COLOR_YELLOW_INFO = "#ffab00";

// GREY
export const COLOR_GREY_BACKGROUND = "#f6f8fd";
export const COLOR_GREY_BORDER = "#ccd6f0";
export const COLOR_GREY_PLACEHOLDER = "#e4e8ee";
export const COLOR_GREY_BLUE = "rgba(4, 3, 152, 0.3)";
export const COLOR_DARK_GREY = "#0a1c37";
export const COLOR_GREY_LIGHT = "#FBFBFB";
export const COLOR_GREY_BUTTON = "#cacccf";
export const COLOR_GREY_BUTTON_DARKER = "#595b5e";
export const COLOR_GREY_STEPPER = "#bcbcbc";
export const COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND = "#eff3fb";
export const COLOR_GREY_PDF_HIGHLIGHT_BACKGROUND_SELECTED = "#b0bbeb";
export const COLOR_GREY_PDF_HIGHLIGHT_FOREGROUND = "#3750cb";
export const COLOR_GREY_EXAM_NAVBAR_COLOR_CODE = "#C4C4C4";

// SHADOW
export const COLOR_SHADOW = "rgba(3, 20, 140, 0.1)";

// TRANSPARENT
export const COLOR_TRANSPARENT = "transparent";
