import React, { FC, useEffect, useState } from "react";
import { StyleSheet, View, TextInput, Text } from "react-native";
import moment from "moment";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18n from "../../services/i18n";

import { editStudentPaper } from "../../modules/examTaking/actions/studentPaper";

import CustomModal from "../atoms/CustomModal";
import Button from "../molecules/Button";
import {
  COLOR_RED,
  COLOR_BLUE_TESTWE,
  COLOR_GREY_BORDER
} from "../../../static/misc/colors";
import {
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  PADDING_SIDES
} from "../../../static/misc/constants";

interface IProps {
  // token: string;
  _editStudentPaper: any;
  studentPapers: any;
}

const ModalResumingExam: FC<IProps> = ({
  _editStudentPaper,
  studentPapers
}) => {
  const [display, setDisplay] = useState(false);
  const [startAt, setStartAt] = useState(null);
  const [text, setText] = useState("");

  useEffect(() => {
    if (!startAt) {
      setStartAt(moment(moment.now()));
    }

    const fnc = window.addEventListener(
      "focus",
      () => {
        setDisplay(true);
      },
      false
    );
    return window.removeEventListener("focus", fnc);
  }, []);

  const sendText = () => {
    const duration = moment
      .duration(moment(moment.now()).diff(startAt))
      .seconds();

    const format = new Date(duration * 1000).toISOString().slice(11, 19);

    const resuming = {
      reason: text,
      date: moment(moment.now()).format(),
      duration: format,
      timezone: "Europe/Paris"
    };

    _editStudentPaper({
      ...studentPapers[0],
      studentPaperResumings: [
        ...(studentPapers[0].studentPaperResumings
          ? [...studentPapers[0].studentPaperResumings, resuming]
          : [resuming])
      ]
    });

    setDisplay(false);
    setText("");
  };

  return (
    <CustomModal
      heightModal="30%"
      widthModal="40%"
      borderRadiusModal={5}
      showCloseButton={false}
      visible={display}
      modalStyle={{ padding: 20 }}
      onCloseModal={() => null}
    >
      <View>
        <Text style={styles.title}>{i18n.t("modal.askResuming")}</Text>
        <View style={styles.inputWrapper}>
          <TextInput
            value={text}
            style={styles.input}
            multiline={10}
            onChangeText={(newText: string) => setText(newText)}
            defaultValue={text}
          />
          <Text style={styles.txtError}>{i18n.t("modal.minWords")}</Text>
        </View>
        <Button
          containerStyle={{
            marginTop: 20,
            ...(text.split(" ").length < 10 || text.split(" ").length > 100
              ? { background: COLOR_RED }
              : {})
          }}
          onPress={sendText}
          label={i18n.t("modal.resumeExam")}
        />
      </View>
    </CustomModal>
  );
};

const mapStateToProps = (state, props) => ({
  studentPapers: state.studentPaper.studentPapers.filter(
    (s) => s.examId === props.examId && s.userId && s.userId === state.user.id
  )
});

const mapdispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        _editStudentPaper: (studentPaper) => editStudentPaper(studentPaper)
      },
      dispatch
    )
  };
};

const styles = StyleSheet.create({
  title: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_14,
    textAlign: "center",
    color: COLOR_BLUE_TESTWE,
    marginBottom: PADDING_SIDES * 0.2
  },
  inputWrapper: {
    borderRadius: 10,
    border: `1px solid ${COLOR_GREY_BORDER}`
  },
  input: {
    borderBottom: `1px solid ${COLOR_GREY_BORDER}`,
    height: 100
    // padding: PADDING_SIDES * 0.1
  },
  txtError: {
    fontFamily: FONT_GILROY_BOLD,
    fontSize: 7,
    color: COLOR_BLUE_TESTWE,
    textAlign: "right",
    marginTop: PADDING_SIDES * 0.1,
    marginBottom: PADDING_SIDES * 0.1,
    marginRight: PADDING_SIDES * 0.2
  }
});

export default connect(mapStateToProps, mapdispatchToProps)(ModalResumingExam);
