import React from "react";
import { StyleSheet, View } from "react-native";
import moment from "moment";
import { ipcRenderer } from "electron";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import i18n from "../../services/i18n";
import {
  DEFAULT_EQUIPMENT_CHECK,
  FONTSIZE_18,
  IS_WEB_ENABLE,
  MIME_JPEG,
  MIME_MP4,
  MIME_WEBM,
  PADDING_SIDES
} from "../../../static/misc/constants";
import BackButton from "../molecules/BackButton";
import ExamInfoStartExam from "../molecules/ExamInfoStartExam";
import ExtraBoldTitle from "../atoms/ExtraBoldTitle";
import { GetErrorAction } from "../../modules/main/types/error";
import { guidGenerator } from "../../../static/misc/utils";
import { ExamOnboarding, ExamType } from "../../modules/exams/types/exam";
import {
  ONBOARDING_ASSETS,
  ONBOARDING_CAMERA_PERMISSION,
  ONBOARDING_CONNECTION,
  ONBOARDING_CUSTOM,
  ONBOARDING_FACE_PHOTO,
  ONBOARDING_GENERAL_CONDITIONS,
  ONBOARDING_ID_PHOTO,
  ONBOARDING_MICROPHONE,
  ONBOARDING_MICROPHONE_PERMISSION,
  ONBOARDING_ORDERED_STEPS,
  ONBOARDING_PERMISSIONS,
  ONBOARDING_PROCTORING_INSTRUCTIONS,
  ONBOARDING_TEACHER_INSTRUCTIONS,
  ONBOARDING_UNLOCK_EXAM,
  ONBOARDING_VIDEO_360,
  ONBOARDING_WELCOME
} from "../../../static/misc/onboarding/onboardingsteps";
import {
  CreateArchiveBeforeUploadAction,
  FILE_TYPE_LOG,
  FILE_TYPE_ONBOARDING_ID,
  FILE_TYPE_ONBOARDING_VIDEO,
  MediaObjectType,
  UploadMediaObjectAction
} from "../../modules/exams/types/attachedfiles";
import {
  prepareProctorData,
  writeProctorFile
} from "../../../static/fileutils";
import OnboardingItemSubTitle from "../molecules/OnboardingItemSubTitle";
import OnBoardingUnlockExamModal from "../molecules/OnBoardingUnlockExamModal";
import OnboardingTeacherInstructions from "./OnBoardingTeacherInstructions";
import { StackNavigatorProp } from "../../router/StackNavigator";
import OnBoardingWelcome from "./OnboardingWelcome";
import OnboardingPhotoStep from "./OnboardingPhotoStep";
import OnboardingVideoStep from "./OnboardingVideoStep";
import OnboardingMicroStep from "./OnboardingMicroStep";
import OnboardingStatementStep from "./OnboardingStatementStep";
import OnBoardingGeneralCondition from "./OnBoardingGeneralConditions";
import OnBoardingPermissionsCheck from "./OnBoardingPermissionsCheck";
import OnBoardingConnectionCheck from "./OnBoardingConnectionCheck";
import OnBoardingProctoringInstructions from "./OnBoardingProctoringInstructions";
import {
  EquipmentCheck,
  PutLastestEquipmentCheck
} from "../../modules/main/types/equipmentCheck";
import { getEquipmentInformation } from "../../services/equipment";
import { initUploadMediaObjectOnAWS } from "../../modules/exams/actions/attachedfiles";
import { postLatestEquipmentCheck } from "../../modules/main/actions/equipmentCheck";
import OnboardingCameraPermissionStep from "./OnboardingCameraPermissionStep";
import OnboardingMicrophonePermissionStep from "./OnboardingMicrophonePermissionStep";
import OnboardingScreenCaptureStep from "./OnboardingScreenCaptureStep";
import ProcessKillerWarningModal from "../molecules/ProcessKillerWarningModal";
import {
  startExitingApplication,
  toggleExamStarting
} from "../../modules/main/actions/status";
import {
  ExitApplicationAction,
  SyncStatus,
  ToggleExamStartingAction
} from "../../modules/main/types/status";
import { RootState } from "../../store/rootreducer";
import OnboardingUnlockExamStep from "./OnboardingUnlockExamStep";

export interface OnboardingInfoType {
  data?: string | Blob | null;
  status: boolean;
  step: number;
}

export interface OnboardingItemProps {
  appDataPath: string;
  audio: boolean;
  canStartExam: boolean;
  currentOnboardingStep: number;
  currentStep: number;
  examId: string;
  onboarding: ExamOnboarding;
  proctoring: boolean;
  proctoringLive: boolean;
  startDate: Date | string;
  token: string;
  isWsAuth: boolean;
  proctoringRoom?: string;
  tokenModalVisible: boolean;
  userId: string;
  currentExam: ExamType;
  delayExpired: boolean;
  displayAvailabilityTag: boolean;
  isOnboardingStarted: boolean;
  hasAgreed: boolean;
  isGoingBackToExam: boolean;
  currentAttachedFiles: MediaObjectType | undefined;
  navigation: StackNavigatorProp;
  isExamAvailable: boolean;
  onBoardingExam: () => Promise<boolean>;
  updateAgreement: () => void;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  getError: (message: string, forceLogout: boolean) => GetErrorAction;
  initUploadMediaObjectOnAWS: (
    userToken: string,
    studentId: string,
    examId: string,
    filename: string,
    fileType: string,
    filepath: string,
    filedata: Buffer | null
  ) => UploadMediaObjectAction;
  onStepChange: (step: number) => void;
  startExam: () => void;
  toggleExamStart: (val: boolean) => ToggleExamStartingAction;
  postLatestEquipment: (
    userToken: string,
    equipmentCheck: EquipmentCheck
  ) => PutLastestEquipmentCheck;
  exitApplication: (action?: string) => ExitApplicationAction;
  examSyncStatus: SyncStatus;
  websocketAuthentification: () => void;
  askForProctoringRoom: () => void;
}

export interface OnboardingItemState {
  canReloadCurrentStep: boolean;
  loader: boolean;
  infos: OnboardingInfoType[];
  equipmentCheck: EquipmentCheck;
  gettingRoom: boolean;
  canAskForRoom: boolean;
  canCaptureDesktop: boolean;
  processKillerWarningModalVisible: boolean;
}

class OnboardingItemView extends React.PureComponent<
  OnboardingItemProps,
  OnboardingItemState
> {
  gettingRoomTimeout: any;

  constructor(props: OnboardingItemProps) {
    super(props);
    this.state = {
      loader: true,
      canReloadCurrentStep: false,
      infos: [],
      equipmentCheck: DEFAULT_EQUIPMENT_CHECK,
      processKillerWarningModalVisible: false,
      gettingRoom: false,
      canAskForRoom: false,
      canCaptureDesktop: false
    };

    this.toggleLoader = this.toggleLoader.bind(this);
    this.updateCurrentStepInfo = this.updateCurrentStepInfo.bind(this);
  }

  componentDidMount(): void {
    const { audio, proctoring, currentExam, proctoringLive } = this.props;
    const { equipmentCheck } = this.state;

    if (audio) ipcRenderer.send("ASK_AUDIO_PERM");
    if (proctoring) ipcRenderer.send("ASK_PROCTORING_PERM");
    if (proctoringLive) ipcRenderer.send("ASK_PROCTORING_LIVE_PERM");

    this.ipcRendererOn().then((resp) =>
      this.setState({
        canCaptureDesktop: IS_WEB_ENABLE ? true : resp.screen
      })
    );

    const newEquipmentCheck = getEquipmentInformation({
      ...equipmentCheck
    });

    newEquipmentCheck.exam = `exams/${currentExam.id}`;

    this.setState({
      equipmentCheck: newEquipmentCheck
    });

    if (!IS_WEB_ENABLE) {
      ipcRenderer.send("GET_EQUIPMENT_INFO");

      ipcRenderer.on("GET_EQUIPMENT_INFO_RESPOND", (_event, arg) => {
        this.onEquipmentValidated(JSON.parse(arg) as EquipmentCheck);
      });
    }
  }

  componentDidUpdate(): void {
    const {
      isWsAuth,
      proctoringRoom,
      proctoringLive,
      startExam,
      websocketAuthentification
    } = this.props;
    const { gettingRoom, canAskForRoom } = this.state;
    if (proctoringLive) {
      if (!isWsAuth) {
        websocketAuthentification();
      } else if (proctoringRoom !== undefined) {
        startExam();
      } else if (!gettingRoom && canAskForRoom) {
        this.startGettingRoom();
      }
    }
  }

  // Triggered when clicking on start exam
  async onBoardingExamCheckProcess(): Promise<void> {
    const {
      currentExam,
      isGoingBackToExam,
      isOnboardingStarted,
      getError,
      onBoardingExam
    } = this.props;

    if (
      (currentExam.examParams?.proctoring ||
        currentExam.examParams?.proctoringLive) &&
      // if user already started onboarding but modal closed by mistake or error he hae 10 more minutes to join exam
      ((isOnboardingStarted &&
        !moment().isBetween(
          currentExam.startDate,
          moment(currentExam.startDate)
            .add(currentExam.timeZeroSecond, "seconds")
            .add(5, "minutes")
            .toDate()
        )) ||
        (!isOnboardingStarted &&
          // if user did not start exam he cant join if timeoption is passed
          ((!isGoingBackToExam &&
            !moment().isBetween(
              currentExam.startDate,
              moment(currentExam.startDate)
                .add(currentExam.timeZeroSecond, "seconds")
                .toDate()
            )) ||
            (isGoingBackToExam &&
              // if user did start exam already he can re-join until timeoption + duration is passed
              !moment().isBetween(
                currentExam.startDate,
                moment(currentExam.startDate)
                  .add(currentExam.duration, "seconds")
                  .add(currentExam.timeZeroSecond, "seconds")
                  .toDate()
              )))))
    ) {
      getError(i18n.t("errors.proctorNot"), false);
    } else onBoardingExam();
  }

  onEquipmentValidated(newEquipmentCheck: EquipmentCheck): void {
    const { equipmentCheck } = this.state;
    this.setState({
      equipmentCheck: {
        syncDate: newEquipmentCheck.syncDate ?? equipmentCheck.syncDate,
        webBrowser: newEquipmentCheck.webBrowser ?? equipmentCheck.webBrowser,
        webVersion: newEquipmentCheck.webVersion ?? equipmentCheck.webVersion,
        desktopVersion:
          newEquipmentCheck.desktopVersion ?? equipmentCheck.desktopVersion,
        osName: newEquipmentCheck.osName ?? equipmentCheck.osName,
        osVersion: newEquipmentCheck.osVersion ?? equipmentCheck.osVersion,
        microphone: newEquipmentCheck.microphone ?? equipmentCheck.microphone,
        sound: newEquipmentCheck.sound ?? equipmentCheck.sound,
        camera: newEquipmentCheck.camera ?? equipmentCheck.camera,
        networkDownload:
          newEquipmentCheck.networkDownload ?? equipmentCheck.networkDownload,
        networkUpload:
          newEquipmentCheck.networkUpload ?? equipmentCheck.networkUpload,
        exam: newEquipmentCheck.exam ?? equipmentCheck.exam,
        memoryTotal:
          newEquipmentCheck.memoryTotal ?? equipmentCheck.memoryTotal,
        memoryAvailable:
          newEquipmentCheck.memoryAvailable ?? equipmentCheck.memoryAvailable,
        proxy: newEquipmentCheck.proxy ?? equipmentCheck.proxy,
        screenNumbers:
          newEquipmentCheck.screenNumbers ?? equipmentCheck.screenNumbers,
        screenResolutions:
          newEquipmentCheck.screenResolutions ??
          equipmentCheck.screenResolutions
      }
    });
  }

  updateCurrentStepInfo(data?: string | Blob | null, status?: boolean): void {
    const { infos } = this.state;
    const { currentOnboardingStep } = this.props;

    const infosTmp = [...infos];
    const mediaIndex = infosTmp.findIndex(
      (m) => m.step === currentOnboardingStep
    );
    let mediaTmp: OnboardingInfoType;

    if (mediaIndex > -1) {
      mediaTmp = { ...infos[mediaIndex] } as OnboardingInfoType;
      mediaTmp.data = data;
      mediaTmp.status = status || false;
      infosTmp[mediaIndex] = mediaTmp;
    } else {
      mediaTmp = {
        data,
        status: status || false,
        step: currentOnboardingStep
      };
      infosTmp.push(mediaTmp);
    }

    this.setState({
      infos: infosTmp,
      canReloadCurrentStep: !!data
    });
  }

  beforeSendingProctorElement(): void {
    const {
      appDataPath,
      currentOnboardingStep,
      examId,
      onboarding,
      token,
      userId,
      // eslint-disable-next-line no-shadow
      initUploadMediaObjectOnAWS
    } = this.props;
    const { infos } = this.state;

    const associatedAsset = ONBOARDING_ASSETS.find(
      (asset) =>
        asset.demoVideo === onboarding.steps[currentOnboardingStep].demoVideo
    );
    const currentInfo = infos.find(
      (info) => info.step === currentOnboardingStep && info.status === true
    );

    if (associatedAsset && currentInfo && currentInfo.data) {
      let mimeType: string;
      let filename: string;

      if (typeof currentInfo.data === "string") {
        mimeType = MIME_JPEG;
        filename = `${userId}_${examId}_${
          associatedAsset.proctorElementId
        }_${moment().format("YYYYMMDDHHmmssSSS")}_${
          associatedAsset.proctorElementId
        }`;
        if (IS_WEB_ENABLE) {
          const response = prepareProctorData(
            examId,
            filename,
            currentInfo.data,
            mimeType,
            appDataPath
          );
          initUploadMediaObjectOnAWS(
            token,
            userId,
            examId,
            response.filename,
            FILE_TYPE_ONBOARDING_ID,
            response.filepath,
            response.filedata
          );
        } else {
          writeProctorFile(
            examId,
            filename,
            currentInfo.data,
            mimeType,
            appDataPath
          )
            .then((response) => {
              if (response) {
                initUploadMediaObjectOnAWS(
                  token,
                  userId,
                  examId,
                  response.filename,
                  FILE_TYPE_ONBOARDING_ID,
                  response.filepath,
                  null
                );
              } else {
                ipcRenderer.send(
                  "LOG_ERROR",
                  `Could not start uploading process for file ${filename} because the response object was empty.`
                );
              }
            })
            .catch((err) => {
              ipcRenderer.send(
                "LOG_ERROR",
                `An exception occurred while trying to write ${filename} on filesystem.`,
                JSON.stringify(err)
              );
            });
        }
      } else {
        mimeType = MIME_WEBM;
        if (!MediaRecorder.isTypeSupported(mimeType)) {
          mimeType = MIME_MP4;
        }
        filename = `${userId}_${examId}_${
          associatedAsset.demoVideo
        }_${moment().format("YYYYMMDDHHmmssSSS")}_${
          associatedAsset.proctorElementId
        }`;

        // need to convert video blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(currentInfo.data as Blob);
        reader.onloadend = () => {
          const data = reader.result?.toString();

          if (data) {
            if (IS_WEB_ENABLE) {
              const response = prepareProctorData(
                examId,
                filename,
                data,
                mimeType,
                appDataPath
              );
              initUploadMediaObjectOnAWS(
                token,
                userId,
                examId,
                response.filename,
                FILE_TYPE_ONBOARDING_VIDEO,
                response.filepath,
                response.filedata
              );
            } else {
              writeProctorFile(
                examId,
                filename,
                data.replace(/data:video\/.*;base64,/, ""),
                mimeType,
                appDataPath
              )
                .then((response) => {
                  if (response) {
                    initUploadMediaObjectOnAWS(
                      token,
                      userId,
                      examId,
                      response.filename,
                      FILE_TYPE_ONBOARDING_VIDEO,
                      response.filepath,
                      null
                    );
                  } else {
                    ipcRenderer.send(
                      "LOG_ERROR",
                      `Could not start uploading process for file ${filename} because the response object was empty.`
                    );
                  }
                })
                .catch((err) => {
                  ipcRenderer.send(
                    "LOG_ERROR",
                    `An exception occurred while trying to write ${filename} on filesystem.`,
                    JSON.stringify(err)
                  );
                });
            }
          }
        };
      }
    }
  }

  ipcRendererOn(): Promise<any> {
    const { proctoring, audio, proctoringLive } = this.props;
    return new Promise((resolve) => {
      if (IS_WEB_ENABLE) {
        resolve({
          microphone: false,
          camera: false,
          screen: false
        });
      } else {
        ipcRenderer.once("PERMISSIONS_ASKING_RESPOND", (event, arg) => {
          resolve(arg);
        });
        ipcRenderer.send("PERMISSIONS_ASKING", {
          microphone: !!audio,
          camera: !!proctoring || !!proctoringLive,
          screen: !!proctoringLive
        });
      }
    });
  }

  startGettingRoom(): void {
    const {
      isWsAuth,
      websocketAuthentification,
      askForProctoringRoom
    } = this.props;

    // re-auth if needed
    if (!isWsAuth) {
      websocketAuthentification();
    }

    askForProctoringRoom();
    // starting the getting room process
    // timeout at 30s to allow the student to re-ask for a room
    this.setState({ gettingRoom: true }, () => {
      this.gettingRoomTimeout = setTimeout(() => {
        this.setState({
          gettingRoom: false
        });
      }, 30000);
    });
  }

  async canStartExam(): Promise<void> {
    const { getError } = this.props;
    const resp = await this.ipcRendererOn();
    if (resp.microphonePerm && resp.cameraPerm) {
      this.finishOnBoarding();
    } else {
      if (!resp.microphonePerm) getError(i18n.t("errors.permAudio"), false);
      if (!resp.cameraPerm) getError(i18n.t("errors.permCamera"), false);
    }
  }

  finishOnBoarding(): void {
    // eslint-disable-next-line no-shadow
    const {
      token,
      startExam,
      postLatestEquipment,
      toggleExamStart
    } = this.props;
    const { equipmentCheck } = this.state;
    toggleExamStart(true);
    postLatestEquipment(token, equipmentCheck);
    startExam();
  }

  toggleLoader(load: boolean): void {
    this.setState({
      loader: load
    });
  }

  nextOnboardingStep(): void {
    const { onStepChange, currentStep } = this.props;
    this.beforeSendingProctorElement();
    this.setState({
      canReloadCurrentStep: false
    });
    onStepChange(currentStep + 1);
  }

  render(): JSX.Element {
    const {
      canStartExam,
      currentOnboardingStep,
      currentStep,
      examId,
      onboarding,
      startDate,
      tokenModalVisible,
      userId,
      currentExam,
      delayExpired,
      displayAvailabilityTag,
      hasAgreed,
      isGoingBackToExam,
      currentAttachedFiles,
      navigation,
      proctoringLive,
      isExamAvailable,
      startExam,
      toggleExamStart,
      updateAgreement,
      createArchiveBeforeUpload,
      getError,
      onStepChange,
      exitApplication,
      examSyncStatus
    } = this.props;
    const {
      infos,
      loader,
      canReloadCurrentStep,
      gettingRoom,
      canCaptureDesktop,
      processKillerWarningModalVisible
    } = this.state;
    const currentInfo = infos.find((m) => m.step === currentOnboardingStep);

    const videoIndex = onboarding.steps[currentOnboardingStep]
      ? ONBOARDING_ASSETS.findIndex(
          (vid) =>
            vid.demoVideo === onboarding.steps[currentOnboardingStep].demoVideo
        )
      : -1;

    const downloadedFiles =
      currentAttachedFiles && currentAttachedFiles.medias.length > 0
        ? currentAttachedFiles.medias.filter((file) => file.isDownloaded).length
        : 0;

    const showRestartAction =
      onboarding.steps[currentOnboardingStep].type ===
        ONBOARDING_MICROPHONE_PERMISSION ||
      onboarding.steps[currentOnboardingStep].type ===
        ONBOARDING_CAMERA_PERMISSION;

    return (
      <View style={styles.containerStyle}>
        <View style={styles.titleContainer}>
          {onboarding.steps[currentOnboardingStep].title && (
            <ExtraBoldTitle
              text={i18n.t(
                `onboardingModal.${onboarding.steps[currentOnboardingStep].title}`
              )}
              textStyle={{ fontSize: FONTSIZE_18 }}
            />
          )}
          <OnboardingItemSubTitle
            activeStepType={onboarding.steps[currentOnboardingStep].type}
            videoIndex={videoIndex}
          />
        </View>
        {_.includes(
          [ONBOARDING_WELCOME],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnBoardingWelcome
            isProctoringLive={currentExam.examParams?.proctoringLive ?? false}
            updateCurrentStepInfo={this.updateCurrentStepInfo}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_TEACHER_INSTRUCTIONS],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnboardingTeacherInstructions
            currentExam={currentExam}
            hasAgreed={hasAgreed}
            updateAgreement={updateAgreement}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_FACE_PHOTO, ONBOARDING_ID_PHOTO],
          onboarding.steps[currentOnboardingStep].type
        ) && !canCaptureDesktop ? (
          <OnboardingScreenCaptureStep
            onPermissionsValidated={() =>
              this.setState({ canCaptureDesktop: true })
            }
          />
        ) : null}
        {_.includes(
          [ONBOARDING_FACE_PHOTO, ONBOARDING_ID_PHOTO],
          onboarding.steps[currentOnboardingStep].type
        ) && canCaptureDesktop ? (
          <OnboardingPhotoStep
            loader={loader}
            canReloadCurrentStep={canReloadCurrentStep}
            currentOnboardingStep={onboarding.steps[currentOnboardingStep]}
            currentInfo={currentInfo}
            type={onboarding.steps[currentOnboardingStep].type}
            updateCurrentStepInfo={this.updateCurrentStepInfo}
            toggleLoader={this.toggleLoader}
            getError={getError}
            onEquipmentValidated={(camera: boolean) =>
              this.onEquipmentValidated({ camera })
            }
          />
        ) : null}
        {_.includes(
          [ONBOARDING_VIDEO_360],
          onboarding.steps[currentOnboardingStep].type
        ) && !canCaptureDesktop ? (
          <OnboardingScreenCaptureStep
            onPermissionsValidated={() =>
              this.setState({ canCaptureDesktop: true })
            }
          />
        ) : null}
        {_.includes(
          [ONBOARDING_VIDEO_360],
          onboarding.steps[currentOnboardingStep].type
        ) && canCaptureDesktop ? (
          <OnboardingVideoStep
            loader={loader}
            canReloadCurrentStep={canReloadCurrentStep}
            currentOnboardingStep={onboarding.steps[currentOnboardingStep]}
            currentInfo={currentInfo}
            type={onboarding.steps[currentOnboardingStep].type}
            updateCurrentStepInfo={this.updateCurrentStepInfo}
            toggleLoader={this.toggleLoader}
            getError={getError}
            onEquipmentValidated={(camera: boolean) =>
              this.onEquipmentValidated({ camera })
            }
          />
        ) : null}
        {_.includes(
          [ONBOARDING_MICROPHONE_PERMISSION],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnboardingMicrophonePermissionStep
            onPermissionsValidated={() => this.updateCurrentStepInfo("", true)}
            onPermissionsNotRequired={() => onStepChange(currentStep + 1)}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_MICROPHONE],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnboardingMicroStep
            currentOnboardingStep={onboarding.steps[currentOnboardingStep]}
            updateCurrentStepInfo={this.updateCurrentStepInfo}
            onEquipmentValidated={(microphone: boolean, sound: boolean) =>
              this.onEquipmentValidated({ microphone, sound })
            }
          />
        ) : null}
        {_.includes(
          [ONBOARDING_CAMERA_PERMISSION],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnboardingCameraPermissionStep
            onPermissionsValidated={() => this.updateCurrentStepInfo("", true)}
            onPermissionsNotRequired={() => onStepChange(currentStep + 1)}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_CUSTOM, ONBOARDING_UNLOCK_EXAM],
          onboarding.steps[currentOnboardingStep].type
        ) &&
        !proctoringLive &&
        onboarding.steps[currentOnboardingStep].actionText !== undefined ? (
          <OnboardingStatementStep
            currentOnboardingStep={onboarding.steps[currentOnboardingStep]}
            currentInfo={currentInfo}
            updateCurrentStepInfo={this.updateCurrentStepInfo}
            getError={getError}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_UNLOCK_EXAM],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnBoardingUnlockExamModal
            isRemoteExam={currentExam.examParams?.remoteExam || false}
            isProctoring={
              currentExam.examParams?.proctoring ||
              currentExam.examParams?.proctoringLive ||
              false
            }
            isVisible={
              _.includes(
                [ONBOARDING_UNLOCK_EXAM],
                onboarding.steps[currentOnboardingStep].type
              ) && !tokenModalVisible
            }
            startDate={
              isGoingBackToExam
                ? moment(startDate)
                    .add(currentExam.timeZeroSecond, "seconds")
                    .add(currentExam.duration, "seconds")
                    .format("HH[h]mm")
                : moment(startDate)
                    .add(currentExam.timeZeroSecond, "seconds")
                    .format("HH[h]mm")
            }
            onClose={(ok: boolean) => {
              if (ok) {
                const timestamp = moment().format("YYYYMMDDHHmmssSSS");
                // ask for log zip creation
                createArchiveBeforeUpload(
                  userId,
                  examId,
                  `${userId}.${timestamp}.logs.zip`,
                  FILE_TYPE_LOG
                );
                this.finishOnBoarding();
              } else {
                onStepChange(currentStep - 1);
              }
            }}
            showTimeOptions={currentExam.examParams?.showTimeOptions ?? true}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_GENERAL_CONDITIONS],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnBoardingGeneralCondition
            updateCurrentStepInfo={this.updateCurrentStepInfo}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_PERMISSIONS],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnBoardingPermissionsCheck
            currentExam={currentExam}
            onPermissionsValidated={() => onStepChange(currentStep + 1)}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_CONNECTION],
          onboarding.steps[currentOnboardingStep].type
        ) ? (
          <OnBoardingConnectionCheck
            updateCurrentStepInfo={this.updateCurrentStepInfo}
            onEquipmentValidated={(
              networkUpload: number,
              networkDownload: number
            ) => this.onEquipmentValidated({ networkUpload, networkDownload })}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_PROCTORING_INSTRUCTIONS, ONBOARDING_UNLOCK_EXAM],
          onboarding.steps[currentOnboardingStep].type
        ) && !proctoringLive ? (
          <OnBoardingProctoringInstructions
            updateCurrentStepInfo={this.updateCurrentStepInfo}
          />
        ) : null}
        {_.includes(
          [ONBOARDING_UNLOCK_EXAM],
          onboarding.steps[currentOnboardingStep].type
        ) && proctoringLive ? (
          <OnboardingUnlockExamStep
            isProctoringLive={proctoringLive}
            gettingRoom={gettingRoom}
          />
        ) : null}
        <View style={styles.buttons}>
          <BackButton
            disabled={gettingRoom}
            navigation={() => {
              if (
                _.includes(
                  [ONBOARDING_WELCOME, ONBOARDING_TEACHER_INSTRUCTIONS],
                  ONBOARDING_ORDERED_STEPS[currentStep].type
                )
              ) {
                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      name: "Main" as any,
                      params: {
                        pageSelected: 0
                      }
                    }
                  ]
                });
              } else {
                let nextStep = currentStep - 1;
                if (
                  !IS_WEB_ENABLE &&
                  ONBOARDING_ORDERED_STEPS[nextStep].type ===
                    ONBOARDING_CONNECTION
                ) {
                  nextStep--;
                }
                if (
                  ONBOARDING_ORDERED_STEPS[nextStep].type ===
                    ONBOARDING_MICROPHONE_PERMISSION ||
                  ONBOARDING_ORDERED_STEPS[nextStep].type ===
                    ONBOARDING_CAMERA_PERMISSION
                ) {
                  nextStep--;
                }
                onStepChange(nextStep);
              }
            }}
            label={i18n.t("exam.back")}
            containerStyle={{ marginLeft: 120 }}
          />
          <ExamInfoStartExam
            key={guidGenerator()}
            isLoadingExamSync={
              ONBOARDING_ORDERED_STEPS[currentStep].type ===
                ONBOARDING_TEACHER_INSTRUCTIONS &&
              examSyncStatus === SyncStatus.PENDING
            }
            currentSyncStatus={
              ONBOARDING_ORDERED_STEPS[currentStep].type ===
              ONBOARDING_TEACHER_INSTRUCTIONS
                ? examSyncStatus
                : undefined
            }
            isInstructionStep={
              ONBOARDING_ORDERED_STEPS[currentStep].type ===
              ONBOARDING_TEACHER_INSTRUCTIONS
            }
            label={
              showRestartAction
                ? i18n.t("exam.restartApplication")
                : isGoingBackToExam
                ? i18n.t("homepage.backToExam")
                : currentOnboardingStep < onboarding.steps.length - 1
                ? i18n.t("examTaking.content.next")
                : i18n.t("exam.startExam")
            }
            totalFiles={
              _.includes(
                [ONBOARDING_TEACHER_INSTRUCTIONS],
                ONBOARDING_ORDERED_STEPS[currentStep].type
              )
                ? (currentExam.allAttachedFiles || []).length
                : 0
            }
            downloadedFiles={
              _.includes(
                [ONBOARDING_TEACHER_INSTRUCTIONS],
                ONBOARDING_ORDERED_STEPS[currentStep].type
              )
                ? downloadedFiles
                : 0
            }
            canStartExam={
              proctoringLive
                ? !gettingRoom
                : _.includes(
                    [ONBOARDING_TEACHER_INSTRUCTIONS],
                    ONBOARDING_ORDERED_STEPS[currentStep].type
                  )
                ? (hasAgreed && isExamAvailable) || isGoingBackToExam
                : !onboarding.steps[currentOnboardingStep].required ||
                  (onboarding.steps[currentOnboardingStep].required === true &&
                    currentInfo !== undefined &&
                    currentInfo.status === true) ||
                  (onboarding.steps[currentOnboardingStep].type ===
                    ONBOARDING_UNLOCK_EXAM &&
                    canStartExam)
            }
            delayExpired={
              _.includes(
                [ONBOARDING_TEACHER_INSTRUCTIONS],
                ONBOARDING_ORDERED_STEPS[currentStep].type
              ) && delayExpired
            }
            available={
              _.includes(
                [ONBOARDING_TEACHER_INSTRUCTIONS],
                ONBOARDING_ORDERED_STEPS[currentStep].type
              ) && displayAvailabilityTag
            }
            startLimitHour={
              isGoingBackToExam
                ? moment(startDate)
                    .add(currentExam.timeZeroSecond, "seconds")
                    .add(currentExam.duration, "seconds")
                    .format("HH[h]mm")
                : moment(startDate)
                    .add(currentExam.timeZeroSecond, "seconds")
                    .format("HH[h]mm")
            }
            onPress={async () => {
              if (
                ONBOARDING_TEACHER_INSTRUCTIONS ===
                ONBOARDING_ORDERED_STEPS[currentStep].type
              ) {
                if (!currentExam.examParams?.openBook) {
                  if (!(window && window.navigator.userAgent.includes("SEB"))) {
                    this.setState({ processKillerWarningModalVisible: true });
                  } else {
                    this.nextOnboardingStep();
                  }
                } else if (isGoingBackToExam) {
                  if (proctoringLive) {
                    this.setState({ canAskForRoom: true });
                  } else {
                    toggleExamStart(true);
                    startExam();
                  }
                } else {
                  this.nextOnboardingStep();
                }
              } else if (showRestartAction) {
                exitApplication("RESTART_APPLICATION");
              } else if (currentOnboardingStep < onboarding.steps.length - 1) {
                this.nextOnboardingStep();
              } else {
                const timestamp = moment().format("YYYYMMDDHHmmssSSS");
                // ask for log zip creation
                createArchiveBeforeUpload(
                  userId,
                  examId,
                  `${userId}.${timestamp}.logs.zip`,
                  FILE_TYPE_LOG
                );
                if (proctoringLive) {
                  this.setState({ canAskForRoom: true });
                } else {
                  this.finishOnBoarding();
                }
              }
            }}
            showTimeOptions={currentExam.examParams?.showTimeOptions ?? true}
          />
        </View>

        <ProcessKillerWarningModal
          isVisible={processKillerWarningModalVisible}
          onClose={(ok: boolean) => {
            if (ok) {
              if (isGoingBackToExam) {
                toggleExamStart(true);
                startExam();
              } else {
                this.setState(
                  { processKillerWarningModalVisible: false },
                  () => {
                    this.nextOnboardingStep();
                  }
                );
              }
            } else {
              this.setState({ processKillerWarningModalVisible: false });
            }
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  containerStyle: { padding: PADDING_SIDES * 0.2, width: "100%" },
  titleContainer: {
    paddingBottom: PADDING_SIDES * 0.1,
    alignItems: "center"
  },
  buttons: {
    paddingTop: PADDING_SIDES * 0.2,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  }
});

const mapStateToProps = (state: RootState) => {
  return {
    examSyncStatus: state.status.examSyncStatus
  };
};

const mapdispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        initUploadMediaObjectOnAWS,
        postLatestEquipment: postLatestEquipmentCheck,
        exitApplication: startExitingApplication,
        toggleExamStart: toggleExamStarting
      },
      dispatch
    )
  };
};

const OnboardingItem = connect(
  mapStateToProps,
  mapdispatchToProps
)(OnboardingItemView);

export default OnboardingItem;
