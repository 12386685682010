/* eslint-disable no-await-in-loop */
// import FormData from "form-data";
import React from "react";
import { View, StyleSheet, Linking } from "react-native";
import { ipcRenderer } from "electron";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import Fullscreen from "../../atomic/atoms/Fullscreen";
import {
  findStudentPaper,
  cipher,
  formatAnswersId,
  removeAsyncStorageItem
} from "../../../static/misc/utils";
import ExamTakingHeader from "../../atomic/templates/examTaking/ExamTakingHeader";
import { StackNavigatorProp } from "../../router/StackNavigator";
import { ExamType } from "../exams/types/exam";
import {
  ClearStudentPaperAction,
  CreateStudentPaperAction,
  EditStudentPaperAction,
  FillStudentPaperAction,
  PostStudentPaperAction,
  StudentAnswerType,
  StudentPaperType,
  UpdateStudentPaperAction
} from "./types/studentPaper";
import ExamTakingBody from "../../atomic/templates/examTaking/ExamTakingBody";
import { ExamTakingRouteProp } from "../../router/ExamFlow";
import ProctorHandler from "../../atomic/organisms/ProctorHandler";
import ExamTakingConfirmModal from "../../atomic/molecules/ExamTakingConfirmModal";
import {
  httpStatus,
  StudentPaperSubmissionAction,
  SubmittingMediasObjectsStatus,
  SubmittingStudentPaperStatus
} from "../main/types/status";
import {
  ClearExamTakingAction,
  EndExamAction,
  ModifyBookMarkedAction
} from "./types/examTaking";
import { PublicKeyType } from "../exams/types/publickeys";
import {
  ChangingExamMyStudPaperStatusAction,
  GetSyncExamAction
} from "../exams/types/exams";
import {
  ATTACHED_FILES,
  CACHED_PROCTORNB_KEY,
  EXAM_FILES,
  IS_PREVIEW,
  IS_WEB_ENABLE,
  MIME_SPREADSHEET,
  NOT_SYNCRO,
  PADDING_SIDES,
  PHONE_NUMBERS,
  SEB_QUITTING_LINK,
  SENT,
  SPREADSHEET_QUESTION,
  STARTED,
  TRAINING
} from "../../../static/misc/constants";
import ExamTakingRemainingDurationWarningModal from "../../atomic/molecules/ExamTakingRemainingDurationWarningModal";
import ExamTakingForcedNavigationModal from "../../atomic/molecules/ExamTakingForcedNavigationModal";
import { ChangeEnum } from "../../atomic/organisms/ExamTakingContent";
import AntiCheatModal from "../../atomic/molecules/AntiCheatModal";
import {
  UploadMediaObjectAction,
  RemoveMediaObjectAction,
  MediaType,
  MediaObjectType,
  FILE_TYPE_LOG,
  CreateArchiveBeforeUploadAction,
  FILE_TYPE_ARCHIVE,
  FILE_TYPE_ARCHIVE_NAME
} from "../exams/types/attachedfiles";
import { MAX_RETRY_NUMBER } from "../../../static/misc/network";
import Loader from "../../atomic/atoms/Loader";
import ProctoringLiveHandler from "../../atomic/organisms/ProctoringLiveHandler";
import PauseExamModal from "../../atomic/molecules/PauseExamModal";
import StopExamModal from "../../atomic/molecules/StopExamModal";
import {
  EmitAuthStudentProctoring,
  EmitExamFinishNotifProctoring,
  EmitGetProctoringRoom,
  EmitProctoringConnectionStatus
} from "../proctoringLive/types/proctoringlive";
import ProctoringExamModal from "../../atomic/molecules/ProctoringExamModal";
import { PutUserTrainingAction, UserState } from "../main/types/user";
import { GetErrorAction, LocalApiErrorAction } from "../main/types/error";
import { getTrainingAttachedFiles } from "../../../static/misc/mockexam";
import i18n from "../../services/i18n";
import { deleteFileOnFs } from "../../../static/fileutils";
import {
  ClearTimerAction,
  CreateTimerAction,
  SetCursorExamAction
} from "./types/timer";
import FullScreenModal from "../../atomic/molecules/FullScreenModal";
import prepareUploadFileToApi from "../../services/web/mediaService";
import ExamTakingSync from "../../atomic/molecules/ExamTakingSync";
import { RootState } from "../../store/rootreducer";
import { hasStudentPaper } from "../exams/utils";
import { api } from "../../services/axios";

export interface ExamTakingViewProps {
  isOnline: boolean;
  studentId: string;
  appDataPath: string;
  currentExam?: ExamType;
  currentUser: UserState;
  studentPapers: StudentPaperType[];
  mediaObjects: MediaObjectType[];
  submittingStudentPaperStatus: SubmittingStudentPaperStatus[];
  submittingMediaObjectsStatus: SubmittingMediasObjectsStatus[];
  examsPublicKeys: PublicKeyType[];
  authorizationToken: string | undefined;
  navigation: StackNavigatorProp;
  route: ExamTakingRouteProp;
  uploadMediaObjectOnAPI: (
    userToken: string,
    examId: string,
    questionId: string,
    filename: string
  ) => UploadMediaObjectAction;
  putHasTakingTraining: (
    userToken: string,
    userId: string,
    hasTakingTraining: boolean,
    takenTrainingDate: string
  ) => PutUserTrainingAction;
  createStudentPaper: (
    examId: string,
    userId: string,
    studentPaperId?: string,
    startDate?: moment.Moment | string
  ) => CreateStudentPaperAction;
  clearStudentPaper: (
    examId: string,
    currentUserId: StudentPaperType["userId"]
  ) => ClearStudentPaperAction;
  changingExamMyStudPaperStatus: (
    examId: string,
    status: string
  ) => ChangingExamMyStudPaperStatusAction;
  setExamCursor: (
    cursor:
      | {
          examPart: string;
          partIndex: string;
        }
      | undefined
  ) => SetCursorExamAction;
  notifyEndOfExamProctoringLive: (
    examId: string,
    roomId: string,
    userId: string,
    endDate: moment.Moment | string
  ) => EmitExamFinishNotifProctoring;
  clearExamTaking: (examId: string | undefined) => ClearExamTakingAction;
  clearTimer: (examId: string) => ClearTimerAction;
  modifyBookMarked: (
    examId: string,
    currentQuestion: string
  ) => ModifyBookMarkedAction;
  editStudentPaper: (
    currentStudentPaper: StudentPaperType
  ) => EditStudentPaperAction;
  postStudentPaper: (
    studentPaper: StudentPaperType,
    userToken: string,
    status?: string,
    currentUserId?: string
  ) => PostStudentPaperAction;
  updateStudentPaper: (
    studentPaper: StudentPaperType,
    userToken: string,
    status?: string
  ) => UpdateStudentPaperAction;
  fillStudentPaper: (
    examId: string,
    studentAnswers: StudentAnswerType[],
    currentUserId: string
  ) => FillStudentPaperAction;
  getSyncExam: (token: string, examId: string) => GetSyncExamAction;
  authentStudentProctoringLive: (token: string) => EmitAuthStudentProctoring;
  sendProctoringConnectionStatus: (
    again: boolean,
    examId: string,
    roomId: string,
    userId: string,
    connectedAt: moment.Moment | string
  ) => EmitProctoringConnectionStatus;
  getProctoringRoomForExam: (
    again: boolean,
    userId: string,
    examId: string
  ) => EmitGetProctoringRoom;
  uploadDataOnAPI: (
    userToken: string,
    examId: string,
    questionId: string,
    form: FormData
  ) => UploadMediaObjectAction;
  initUploadMediaObjectOnAWS: (
    userToken: string,
    studentId: string,
    examId: string,
    filename: string,
    filetype: string,
    filepath: string,
    filedata: Buffer | null
  ) => UploadMediaObjectAction;
  initStudentPaperSubmission: (
    retry: boolean,
    studentPaper: StudentPaperType
  ) => StudentPaperSubmissionAction;
  stopStudentPaperSubmission: (
    abort: boolean,
    studentPaper: StudentPaperType
  ) => StudentPaperSubmissionAction;
  removeMediaObject: (examId: string) => RemoveMediaObjectAction;
  createArchiveBeforeUpload: (
    userId: string,
    examId: string,
    filename: string,
    archiveType: string
  ) => CreateArchiveBeforeUploadAction;
  localApiError: (err: any) => LocalApiErrorAction;
  getError: (errorMessage: string, forceLogout: boolean) => GetErrorAction;
  proctoringExamPause: boolean;
  proctoringExamStop: boolean;
  proctorIdentity: string | undefined;
  proctorAudioEnable: boolean;
  isWsAuth: boolean;
  proctoringRoom?: string;
  reconnection: boolean;
  twilioAccessToken?: string;
  endExamTaking: () => EndExamAction;
  createExamTimer: (currentExam: ExamType) => CreateTimerAction;
}

interface ExamTakingViewState {
  isProctoringLive: boolean;
  proctoringStopExam: boolean;
  showExam: boolean;
  attachedFiles: MediaType[] | undefined;
  currentStudentPaper: StudentPaperType | undefined;
  isStudentPaperReady: boolean;
  isTraining: boolean;
  startedExam: boolean;
  enableProctoring: boolean;
  proctorArchiveSent: boolean | null;
  processCheating: string;
  displayConfirmModal: boolean;
  allowConfirmModalDismiss: boolean;
  displaySyncModal: boolean;
  displayAntiCheatModal: boolean;
  displayForcedNavigationModal: boolean;
  displayRemainingDurationWarningModal: boolean;
  displayProctoringModal: boolean;
  typeOfChangeQuestion: ChangeEnum;
  forceStopTimers: boolean;
  stopProctoringAudioRecording: boolean;
  isWebFullScreen: boolean;
  showModalFullScreen: boolean;
  navigateToNext: (changeType: ChangeEnum) => void;
}

class ExamTakingView extends React.Component<
  ExamTakingViewProps,
  ExamTakingViewState
> {
  lastFullScreen = false;

  constructor(props: ExamTakingViewProps) {
    super(props);
    const { route, currentExam } = props;

    this.state = {
      isProctoringLive:
        currentExam?.examParams?.proctoringLive !== undefined
          ? currentExam?.examParams?.proctoringLive
          : false,
      proctoringStopExam: false,
      showExam: false,
      startedExam: false,
      attachedFiles: undefined,
      isStudentPaperReady: false,
      currentStudentPaper: undefined,
      displayConfirmModal: false,
      processCheating: "",
      displayProctoringModal: false,
      allowConfirmModalDismiss: true,
      displaySyncModal: false,
      enableProctoring:
        currentExam?.examParams?.proctoring ||
        currentExam?.examParams?.proctoringLive ||
        false,
      proctorArchiveSent:
        currentExam?.examParams?.proctoring ||
        currentExam?.examParams?.proctoringLive
          ? false
          : null,
      displayAntiCheatModal: false,
      displayForcedNavigationModal: false,
      displayRemainingDurationWarningModal: false,
      isTraining:
        (route && route.params?.isTraining) ||
        props.currentExam?.id === TRAINING,
      typeOfChangeQuestion: ChangeEnum.NONE,
      forceStopTimers: false,
      stopProctoringAudioRecording: false,
      isWebFullScreen: false,
      showModalFullScreen: false,
      navigateToNext: () => undefined
    };

    this.submitStudentPaper.bind(this);
    this.backToHomepage.bind(this);
    this.toggleAudioProctoring = this.toggleAudioProctoring.bind(this);
    this.askForProctoringRoom = this.askForProctoringRoom.bind(this);
    this.confirmProctorStudentConnected = this.confirmProctorStudentConnected.bind(
      this
    );
    this.editAttachedFileUri = this.editAttachedFileUri.bind(this);
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount(): Promise<void> {
    const { currentExam, createExamTimer } = this.props;
    if (IS_PREVIEW && currentExam) {
      createExamTimer(currentExam);
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      currentExam,
      mediaObjects,
      isWsAuth,
      authorizationToken,
      proctoringRoom,
      studentId,
      createStudentPaper,
      setExamCursor,
      authentStudentProctoringLive,
      createExamTimer,
      getProctoringRoomForExam,
      getError,
      currentUser,
      studentPapers
    } = this.props;
    const { isTraining, isProctoringLive } = this.state;
    ipcRenderer.send(
      "LOG_INFO",
      `PAGEMOVE - ExamTakingView uuid: ${currentExam?.id}`
    );

    if (currentExam && currentExam.examParts && currentExam.examParts[0])
      setExamCursor({ examPart: "", partIndex: "" });
    // Authentication for websocket proctoring live
    if (isProctoringLive && !IS_PREVIEW) {
      if (!isWsAuth && authorizationToken) {
        // should display info modal to hide exam
        // should also stop timers
        authentStudentProctoringLive(authorizationToken);
        this.setState({
          displayProctoringModal: true
        });
      } else if (!proctoringRoom && currentExam) {
        getProctoringRoomForExam(false, studentId, currentExam.id);
      }
    }
    // if not openbook, go in fullscreen
    if (IS_WEB_ENABLE && !currentExam?.examParams?.openBook) {
      window.addEventListener("blur", this.onBlurEvent);
      window.addEventListener("focus", this.onFocusEvent);
      this.setState({ isWebFullScreen: true });
    }

    if (!IS_PREVIEW && !IS_WEB_ENABLE) {
      // Cheating control
      ipcRenderer.on("cheat", (event, arg) => {
        ipcRenderer.send(
          "LOG_INFO",
          "Received cheat warning from main process",
          arg
        );
        getError(i18n.t("exam.cheatMessage"), false);
      });

      // setting listener to change archive sending status
      if (
        currentExam?.examParams?.proctoring ||
        currentExam?.examParams?.proctoringLive
      ) {
        ipcRenderer.on("PROCTOR_ARCHIVE_UPLOAD_STATUS", (evt, args) => {
          const data = JSON.parse(args);
          if (data.status === "success" && data.examId === currentExam.id) {
            this.setState({
              proctorArchiveSent: true
            });
          }
        });
      }
    }

    // Retrieving medias linked to the exam
    const attachedFiles =
      currentExam?.id === TRAINING
        ? getTrainingAttachedFiles()
        : mediaObjects.find((e) => e.examId === currentExam?.id)?.medias;

    if (
      !currentExam ||
      !currentExam.examParts ||
      currentExam.examParts.length === 0
    ) {
      this.backToHomepage();
      return;
    }
    // Is real exam
    // Retrieving (if already exists) or generating the student paper for the exam
    if (!isTraining) {
      if (
        currentExam?.id &&
        !hasStudentPaper(currentExam.id, currentUser?.id, studentPapers)
      ) {
        createStudentPaper(currentExam.id, currentUser.id, undefined);
        this.setState({
          isStudentPaperReady: true,
          attachedFiles,
          showExam: true
        });
      } else {
        this.setState({
          isStudentPaperReady: true,
          currentStudentPaper: {
            ...this.getCurrentStudentPaper()
          } as StudentPaperType,
          attachedFiles,
          showExam: true
        });
      }
    } else {
      // Is a training exam
      // Generating training student paper
      createExamTimer(currentExam);

      createStudentPaper(currentExam.id, currentUser.id, undefined);
      this.setState({
        isStudentPaperReady: true,
        attachedFiles,
        showExam: true
      });
    }
  }

  componentDidUpdate(prevProps: ExamTakingViewProps): void {
    const {
      studentPapers,
      currentExam,
      isOnline,
      isWsAuth,
      proctoringRoom,
      submittingStudentPaperStatus,
      authorizationToken,
      examsPublicKeys,
      proctoringExamStop,
      proctoringExamPause,
      postStudentPaper,
      currentUser
    } = this.props;
    const {
      currentStudentPaper,
      isTraining,
      startedExam,
      isProctoringLive
    } = this.state;
    const currentStudentPaperStatus = submittingStudentPaperStatus.find(
      (studentPaper) => currentExam && studentPaper.examId === currentExam.id
    );
    const prevStudentPaperStatus = prevProps.submittingStudentPaperStatus.find(
      (studentPaper) => currentExam && studentPaper.examId === currentExam.id
    );

    // IF Proctoring live is enabled
    if (isProctoringLive) {
      if (
        isWsAuth &&
        proctoringRoom &&
        prevProps.proctoringRoom !== proctoringRoom
      ) {
        this.setState({
          displayProctoringModal: false
        });
      }
      // Force stop exam
      if (
        proctoringExamStop !== prevProps.proctoringExamStop &&
        proctoringExamStop
      )
        this.setState({ proctoringStopExam: true });

      // Pause/Unpause exam on proctoring
      if (proctoringExamPause !== prevProps.proctoringExamPause) {
        this.setState({ forceStopTimers: proctoringExamPause });
      }
    }

    // Create the student paper on the API if the user is online
    if (
      !isTraining &&
      !IS_PREVIEW &&
      isOnline &&
      !startedExam &&
      authorizationToken &&
      currentStudentPaper &&
      currentStudentPaper.studentAnswers &&
      currentStudentPaper.studentAnswers.length === 0 &&
      !currentStudentPaper.id &&
      currentStudentPaper.endDate === null &&
      studentPapers.find(
        (studentPaper) =>
          studentPaper.examId?.toString() ===
          currentStudentPaper.examId?.toString()
      )
    ) {
      const cipheredCurrentStudentPaper = { ...currentStudentPaper };
      const tmpCurrentAnswers: StudentAnswerType[] = [
        ...(currentStudentPaper?.studentAnswers as StudentAnswerType[])
      ];
      const currentPublicKeyIndex = examsPublicKeys.findIndex(
        (exams) => exams.examId === currentStudentPaper?.examId
      );
      // We need to cipher the student paper even if empty because otherwise the API will deny it
      if (currentPublicKeyIndex >= 0) {
        const currentPublicKey = examsPublicKeys[currentPublicKeyIndex];
        const message = JSON.stringify(tmpCurrentAnswers);
        cipheredCurrentStudentPaper.studentAnswers = cipher(
          currentPublicKey.publicKey.toString(),
          message
        ) as any;
        this.setState({ startedExam: true }, () => {
          postStudentPaper(
            cipheredCurrentStudentPaper,
            authorizationToken,
            STARTED,
            currentUser.id
          );
        });
      }
    }

    // Update student paper in state whenever it is updated in props
    // We want to update it even if it is a training, to avoid bug on progress bar and when "submitting" it at the end
    if (!_.isEqual(studentPapers, prevProps.studentPapers)) {
      this.setState({
        currentStudentPaper: {
          ...this.getCurrentStudentPaper()
        } as StudentPaperType
      });
    }

    // Display the synchronisation modal if the student paper submission has been modified in store
    // (if INIT/PENDING, FAIL or SUCCESS)
    if (
      !isTraining &&
      !IS_PREVIEW &&
      currentStudentPaper &&
      currentStudentPaper.endDate !== null &&
      currentStudentPaperStatus &&
      (!prevStudentPaperStatus ||
        (prevStudentPaperStatus &&
          prevStudentPaperStatus?.submittingStudentPaper !==
            currentStudentPaperStatus?.submittingStudentPaper))
    ) {
      this.setState({
        displaySyncModal: true
      });
    }
    // If student paper submission is INIT && hasMedias, checking for medias and launching upload functions
    // ELSE IF because we don't want to go through this condition if the state was just updated to display modals
    else if (currentStudentPaper && currentStudentPaper.endDate !== null) {
      this.submitMediasBeforeStudentPaper();
    }
  }

  // removing listeners before quitting the exam
  componentWillUnmount(): void {
    const { currentExam } = this.props;

    // TODO SBE: proctoring live in web version
    if (!IS_PREVIEW && !IS_WEB_ENABLE) {
      if (
        ipcRenderer.rawListeners("PROCTOR_ARCHIVE_UPLOAD_STATUS").length > 0
      ) {
        ipcRenderer.removeAllListeners("PROCTOR_ARCHIVE_UPLOAD_STATUS");
      }

      if (ipcRenderer.rawListeners("cheat").length > 0) {
        ipcRenderer.removeAllListeners("cheat");
      }
    }

    if (IS_WEB_ENABLE && !currentExam?.examParams?.openBook) {
      window.removeEventListener("blur", this.onBlurEvent);
      window.removeEventListener("focus", this.onFocusEvent);
    }
  }

  onBlurEvent(): void {
    ipcRenderer.send("info", "WEB // WINDOW IS LOOSED / BLURRED");
  }

  onFocusEvent(): void {
    ipcRenderer.send("info", "WEB // WINDOW IS FOCUSED");
  }

  // Triggered to upload medias for the exam
  // (during the exam only, when switching part/question)
  onUploadMediaObjects(
    studentAnswers: StudentAnswerType[],
    isLastQuestion: boolean
  ): void {
    const {
      authorizationToken,
      isOnline,
      currentExam,
      uploadMediaObjectOnAPI,
      uploadDataOnAPI
    } = this.props;
    const { attachedFiles } = this.state;

    if (
      currentExam &&
      isOnline &&
      authorizationToken &&
      studentAnswers.length > 0
    ) {
      studentAnswers.forEach((answer) => {
        if (IS_WEB_ENABLE) {
          if (answer.mediaData && answer.mediaData.length > 0) {
            answer.mediaData.forEach((media) => {
              if (
                this.shouldUploadMediaObject(
                  attachedFiles,
                  isLastQuestion,
                  media.filename
                )
              ) {
                const formData = prepareUploadFileToApi(media);
                if (formData) {
                  uploadDataOnAPI(
                    authorizationToken,
                    currentExam.id,
                    answer.question.replace("/questions/", ""),
                    formData
                  );
                }
              }
            });
          }
        } else if (answer.mediaObjects && answer.mediaObjects.length > 0) {
          answer.mediaObjects.forEach((media) => {
            if (
              this.shouldUploadMediaObject(attachedFiles, isLastQuestion, media)
            ) {
              uploadMediaObjectOnAPI(
                authorizationToken,
                currentExam.id,
                answer.question.replace("/questions/", ""),
                media
              );
            }
          });
        }
      });
    }
  }

  onFullscreenChange(change: boolean) {
    if (this.lastFullScreen !== change) {
      this.lastFullScreen = change;
      if (!change) {
        this.setState({
          showModalFullScreen: true,
          isWebFullScreen: false
        });
      } else {
        this.setState({
          showModalFullScreen: false
        });
      }
      this.setState({ isWebFullScreen: change });
    }
  }

  // Retrieving the student paper from the store if already exists
  getCurrentStudentPaper(): StudentPaperType | undefined {
    const { studentPapers, currentExam, currentUser } = this.props;

    return findStudentPaper(studentPapers, currentExam?.id, currentUser.id);
  }

  // Disabling the anti-cheat system
  disableAntiCheat = (): void => {
    this.setState({ displayAntiCheatModal: false, forceStopTimers: false });
    ipcRenderer.send("STOP_PROCESS_KILLER");
  };

  shouldUploadMediaObject(
    attachedFiles: MediaType[] | undefined,
    isLastQuestion: boolean,
    mediaName: string
  ): boolean {
    // trying to check if the media is a spreadsheet - if yes, it will contain the original attached file id
    const attachedFile = attachedFiles?.find((file) =>
      mediaName.includes(file.mediaId)
    );

    // if media is a spreadsheet, we want to upload ONLY at the end of the exam, otherwise it will cause problems
    // If media was not already uploaded, its name is the filename on the filesystem and starts with internal-
    // Creating the media object entry in the status array to check on the upload progress
    return (
      (attachedFile &&
        _.includes(MIME_SPREADSHEET, attachedFile.mimeType) &&
        isLastQuestion) ||
      mediaName.indexOf("internal-") > -1
    );
  }

  // mostly for spreadsheet : updating the uri of the attached file
  // so if the student navigates to a spreadsheet question he already answered,
  // the answer file will be used as base instead of the original exam attached file
  editAttachedFileUri(mediaId: string, uri: string): void {
    const { attachedFiles } = this.state;

    const mediaIndex = attachedFiles?.findIndex((e) => e.mediaId === mediaId);

    if (mediaIndex !== undefined && mediaIndex >= 0 && attachedFiles) {
      const attachedFilesTmp = [...attachedFiles];
      const attachedFile = attachedFilesTmp[mediaIndex];
      attachedFile.blobUrl = uri;
      attachedFilesTmp[mediaIndex] = attachedFile;
      this.setState({ attachedFiles: attachedFilesTmp });
    }
  }

  shouldSubmitMedias(
    submittingMediaObjectsStatus: SubmittingMediasObjectsStatus[],
    currentExam: ExamType,
    answer: StudentAnswerType,
    mediaName: string
  ): boolean {
    // If media was not already uploaded, its name is the filename on the filesystem and starts with internal-
    if (mediaName.indexOf("internal-") === -1) return false;

    const mediaStatusIndex = submittingMediaObjectsStatus.findIndex(
      (mediaStatus) =>
        mediaStatus.examId === currentExam.id &&
        mediaStatus.questionId.replace("/questions/", "") ===
          answer.question.replace("/questions/", "")
    );

    return (
      mediaStatusIndex > -1 &&
      (submittingMediaObjectsStatus[mediaStatusIndex]
        .submittingMediaObjectStatus === httpStatus.INIT ||
        (submittingMediaObjectsStatus[mediaStatusIndex]
          .submittingMediaObjectStatus === httpStatus.FAIL &&
          submittingMediaObjectsStatus[mediaStatusIndex].retryCount <
            MAX_RETRY_NUMBER))
    );
  }

  // Ensuring the media upload success before trying to submit the student paper
  // (end of exam only)
  submitMediasBeforeStudentPaper(): void {
    const {
      currentExam,
      submittingStudentPaperStatus,
      submittingMediaObjectsStatus,
      authorizationToken,
      isOnline,
      uploadMediaObjectOnAPI,
      uploadDataOnAPI
    } = this.props;
    const { currentStudentPaper, isTraining } = this.state;
    const currentStudentPaperStatus = submittingStudentPaperStatus.find(
      (studentPaper) => currentExam && studentPaper.examId === currentExam.id
    );

    // If the student paper submission has been initialized and the associated media objects status is pending,
    // then proceed to the media object upload
    if (
      !isTraining &&
      !IS_PREVIEW &&
      isOnline &&
      currentExam &&
      authorizationToken &&
      currentStudentPaperStatus &&
      currentStudentPaperStatus.submittingStudentPaperStatus ===
        httpStatus.INIT &&
      currentStudentPaperStatus.submittingMediaObjectsStatus ===
        httpStatus.PENDING &&
      typeof currentStudentPaper?.studentAnswers !== "string"
    ) {
      currentStudentPaper?.studentAnswers.forEach((answer) => {
        if (IS_WEB_ENABLE) {
          if (answer.mediaData && answer.mediaData.length > 0) {
            answer.mediaData.forEach((media) => {
              if (
                this.shouldSubmitMedias(
                  submittingMediaObjectsStatus,
                  currentExam,
                  answer,
                  media.filename
                )
              ) {
                const formData = prepareUploadFileToApi(
                  media,
                  answer.questionType === SPREADSHEET_QUESTION
                );
                if (formData) {
                  uploadDataOnAPI(
                    authorizationToken,
                    currentExam.id,
                    answer.question.replace("/questions/", ""),
                    formData
                  );
                }
              }
            });
          }
        } else if (answer.mediaObjects && answer.mediaObjects.length > 0) {
          answer.mediaObjects.forEach((media) => {
            if (
              this.shouldSubmitMedias(
                submittingMediaObjectsStatus,
                currentExam,
                answer,
                media
              )
            ) {
              // Creating the media object entry in the status array to check on the upload progress
              uploadMediaObjectOnAPI(
                authorizationToken,
                currentExam.id,
                answer.question.replace("/questions/", ""),
                media
              );
            }
          });
        }
      });
    }
    // If student paper submission is INIT && there is no media is associated to the exam,
    // launch the student paper submissions function
    else if (
      !isTraining &&
      !IS_PREVIEW &&
      currentStudentPaperStatus &&
      currentStudentPaperStatus.submittingStudentPaperStatus ===
        httpStatus.INIT &&
      (!isOnline ||
        currentStudentPaperStatus.submittingMediaObjectsStatus ===
          httpStatus.NONE ||
        currentStudentPaperStatus.submittingMediaObjectsStatus ===
          httpStatus.SUCCESS)
    ) {
      this.submitStudentPaper();
    }
  }

  // Display the confirmation modal (at the end of the exam, to submit the student paper)
  toggleConfirmModal(visible: boolean): void {
    const {
      displayForcedNavigationModal,
      displayRemainingDurationWarningModal
    } = this.state;

    const { currentExam } = this.props;

    this.setState({
      displayConfirmModal: visible,
      displayForcedNavigationModal: false,
      displayRemainingDurationWarningModal: false,
      forceStopTimers: displayRemainingDurationWarningModal === true,
      allowConfirmModalDismiss:
        displayForcedNavigationModal !== true &&
        displayRemainingDurationWarningModal !== true &&
        (currentExam?.duration === 0 ||
          (currentExam?.remainingDuration !== undefined &&
            currentExam?.remainingDuration > 0))
    });
  }

  displayForcedNavigationModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void {
    const { displayConfirmModal } = this.state;

    // We don't want to display the forced navigation modal when
    // the end of exam confirmation modal is already displayed.
    // But we want to block the confirm modal dismiss function
    if (displayConfirmModal) {
      this.setState({
        allowConfirmModalDismiss: false
      });
      return;
    }

    if (visible && callback && changeType !== undefined) {
      this.setState({
        displayForcedNavigationModal: visible,
        displayRemainingDurationWarningModal: false,
        typeOfChangeQuestion: changeType,
        navigateToNext: callback
      });
    } else {
      this.setState({
        displayForcedNavigationModal: visible,
        displayRemainingDurationWarningModal: false,
        typeOfChangeQuestion: ChangeEnum.NONE,
        navigateToNext: () => undefined
      });
    }
  }

  // Toggle the remaining duration warning modal (when switching timed part/question)
  toggleRemainingDurationWarningModal(
    visible: boolean,
    changeType?: ChangeEnum,
    callback?: (changeType: ChangeEnum) => void
  ): void {
    if (visible && callback !== undefined && changeType !== undefined) {
      this.setState({
        displayRemainingDurationWarningModal: visible,
        typeOfChangeQuestion: changeType,
        navigateToNext: callback
      });
    } else {
      this.setState({
        displayRemainingDurationWarningModal: visible,
        typeOfChangeQuestion: ChangeEnum.NONE,
        navigateToNext: () => undefined
      });
    }
  }

  toggleAudioProctoring(): void {
    const { stopProctoringAudioRecording } = this.state;

    this.setState({
      stopProctoringAudioRecording: !stopProctoringAudioRecording
    });
  }

  sendArchiveProctoring(): void {
    const {
      currentExam,
      authorizationToken,
      studentId,
      isOnline,
      createArchiveBeforeUpload
    } = this.props;

    if (currentExam && authorizationToken && isOnline) {
      if (IS_WEB_ENABLE) {
        this.setState({
          proctorArchiveSent: true
        });
      } else {
        // ask for log zip creation
        createArchiveBeforeUpload(
          studentId,
          currentExam?.id,
          FILE_TYPE_ARCHIVE_NAME,
          FILE_TYPE_ARCHIVE
        );
      }
    }
  }

  // Submitting the student paper
  submitStudentPaper(): void {
    const {
      isOnline,
      authorizationToken,
      currentExam,
      postStudentPaper,
      editStudentPaper,
      updateStudentPaper,
      currentUser
    } = this.props;

    if (
      currentExam?.examParams?.proctoring ||
      currentExam?.examParams?.proctoringLive
    ) {
      this.sendArchiveProctoring();
    }

    const currentStudentPaper = this.getCurrentStudentPaper();
    let cipheredStudentPaper = currentStudentPaper;
    // checking if student paper is not already ciphered
    if (typeof currentStudentPaper?.studentAnswers !== "string") {
      // Ciphering the student answers
      cipheredStudentPaper = this.cipherExam({
        ...currentStudentPaper
      } as StudentPaperType);
    }
    if (isOnline && cipheredStudentPaper) {
      // Set in localStorage the studentAnswers with the ciphered one
      editStudentPaper(cipheredStudentPaper);
    }
    if (
      authorizationToken &&
      isOnline &&
      cipheredStudentPaper &&
      !cipheredStudentPaper?.id
    ) {
      // In case the student was not online when started the exam, proceed to do a POST request to create it on the API
      postStudentPaper(
        cipheredStudentPaper,
        authorizationToken,
        SENT,
        currentUser.id
      );
    } else if (
      authorizationToken &&
      isOnline &&
      cipheredStudentPaper &&
      cipheredStudentPaper?.id
    ) {
      // In case the student was online when started the exam and already created the student paper on the API,
      // proceed to do a PUT request to modify it on the API
      updateStudentPaper(cipheredStudentPaper, authorizationToken, SENT);
    }
  }

  notifyEndOfExamPL(): void {
    const {
      notifyEndOfExamProctoringLive,
      studentId,
      currentExam,
      proctoringRoom,
      authorizationToken
    } = this.props;

    // Make sure that we change the student ws status to "finished"
    api
      .put(
        `exam-proctoring/exam/${currentExam?.id}/student/${studentId}`,
        {
          statusPL: "finished"
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${authorizationToken}`
          }
        }
      )
      .then((response) => {
        ipcRenderer.send("LOG_INFO", "NOTIFY_END_OF_EXAM-PL-SUCCESS", {
          message: "Student has finished from proctoring live",
          data: {
            studentId,
            examId: currentExam?.id,
            proctoringRoom,
            response
          }
        });
      })
      .finally(() => {
        notifyEndOfExamProctoringLive(
          currentExam?.id ?? "",
          proctoringRoom ?? "",
          studentId,
          moment()
        );
      })
      .catch((error) => {
        ipcRenderer.send("LOG_ERROR", "NOTIFY_END_OF_EXAM-PL-ERROR", {
          message:
            "Error while trying to change student status to finished from proctoring live",
          data: {
            studentId,
            examId: currentExam?.id,
            proctoringRoom,
            error
          }
        });
      });
  }

  // Triggered at the end of the student paper submission
  async endStudentPaperSubmission(): Promise<void> {
    const {
      isOnline,
      submittingStudentPaperStatus,
      currentExam,
      studentId,
      authorizationToken,
      stopStudentPaperSubmission,
      editStudentPaper,
      createArchiveBeforeUpload
    } = this.props;
    const { currentStudentPaper, attachedFiles } = this.state;

    if (!currentStudentPaper) return;

    // Retrieving the student paper submission status from the store
    const currentStudentPaperStatus = submittingStudentPaperStatus.find(
      (studentPaper) => currentExam && studentPaper.examId === currentExam.id
    );

    // Clearing the attached files if any
    if (attachedFiles) {
      this.clearAttachedFiles(attachedFiles);
    }

    // If the submission status is SUCCESS
    if (
      currentStudentPaper &&
      currentStudentPaperStatus &&
      currentStudentPaperStatus.submittingStudentPaper === false &&
      (currentStudentPaperStatus.submittingStudentPaperStatus ===
        httpStatus.SUCCESS ||
        currentStudentPaperStatus.submittingStudentPaperStatus ===
          httpStatus.FAIL)
    ) {
      if (
        currentStudentPaperStatus.submittingStudentPaperStatus ===
        httpStatus.SUCCESS
      ) {
        // Clearing the media objects from the store
        this.clearAnswersMediaObjects();
        // Ending the student paper submission
        stopStudentPaperSubmission(false, currentStudentPaper);
      }
    }
    // OFFLINE - we abort student paper submission and we update to the ciphered version
    else if (!isOnline && currentStudentPaper) {
      let cipheredStudentPaper = currentStudentPaper;
      // checking if student paper is not already ciphered
      if (typeof currentStudentPaper.studentAnswers !== "string") {
        cipheredStudentPaper = this.cipherExam(currentStudentPaper);
      }
      stopStudentPaperSubmission(true, cipheredStudentPaper);
      editStudentPaper(cipheredStudentPaper);
    }

    // Sending logs after studentpaper submission
    if (authorizationToken && currentExam?.id) {
      const timestamp = moment().format("YYYYMMDDHHmmssSSS");
      // ask for log zip creation
      createArchiveBeforeUpload(
        studentId,
        currentExam?.id,
        `${studentId}.${timestamp}.logs.zip`,
        FILE_TYPE_LOG
      );
    }

    // Redirecting the student to the homepage
    this.backToHomepage();
  }

  // Ciphering the student answers
  cipherExam(tmpCurrentStudentPaper: StudentPaperType): StudentPaperType {
    const { examsPublicKeys } = this.props;
    // CIPHER
    const cipheredCurrentStudentPaper = { ...tmpCurrentStudentPaper };
    const tmpCurrentAnswers: StudentAnswerType[] = [
      ...(tmpCurrentStudentPaper?.studentAnswers as StudentAnswerType[])
    ];
    const currentPublicKeyIndex = examsPublicKeys.findIndex(
      (exams) => exams.examId === tmpCurrentStudentPaper?.examId
    );
    if (currentPublicKeyIndex >= 0) {
      const currentPublicKey = examsPublicKeys[currentPublicKeyIndex];
      const message = JSON.stringify(tmpCurrentAnswers);
      cipheredCurrentStudentPaper.studentAnswers = cipher(
        currentPublicKey.publicKey.toString(),
        message
      ) as any;
    }
    // END CIPHERING
    return cipheredCurrentStudentPaper;
  }

  askForProctoringRoom(): void {
    const { studentId, currentExam, getProctoringRoomForExam } = this.props;

    if (
      currentExam &&
      currentExam.id &&
      currentExam.examParams?.proctoringLive
    ) {
      getProctoringRoomForExam(true, studentId, currentExam.id);
    }
  }

  // Triggered when clicking the Finish exam button
  finishExam(): void {
    const { isTraining, isProctoringLive } = this.state;
    const {
      changingExamMyStudPaperStatus,
      initStudentPaperSubmission
    } = this.props;

    const currentStudentPaper = this.getCurrentStudentPaper();

    let tmpCurrentStudentPaper: StudentPaperType = {
      ...currentStudentPaper
    } as StudentPaperType;

    if (currentStudentPaper && !isTraining) {
      // Changing exam mystudentpaper synchronisation status
      if (tmpCurrentStudentPaper.examId)
        changingExamMyStudPaperStatus(
          tmpCurrentStudentPaper.examId,
          NOT_SYNCRO
        );
      tmpCurrentStudentPaper.endDate = moment().utc().format();
      tmpCurrentStudentPaper = formatAnswersId(tmpCurrentStudentPaper);

      // If is is a proctoring live exam, notify the proctor that exam is finished
      if (isProctoringLive) {
        this.notifyEndOfExamPL();
      }

      this.setState(
        {
          currentStudentPaper: tmpCurrentStudentPaper,
          forceStopTimers: true,
          displayConfirmModal: false
        },
        () => {
          // Initiating the student paper submission
          initStudentPaperSubmission(false, tmpCurrentStudentPaper);
        }
      );
    } else if (isTraining) this.backToHomepage();
  }

  // Cleaning the attached files from the filesystem and the store
  async clearAttachedFiles(attachedFiles: MediaType[]): Promise<void> {
    const { removeMediaObject, appDataPath, currentExam } = this.props;

    for (let i = 0; i < attachedFiles.length; i++) {
      const item = attachedFiles[i];
      await deleteFileOnFs(item.mediaId, appDataPath, ATTACHED_FILES);
    }
    if (currentExam) removeMediaObject(currentExam?.id);

    this.setState({ attachedFiles: undefined });
  }

  // Cleaning the media objects from the filesystem after ensuring it has been successfully uploaded
  async clearAnswersMediaObjects(): Promise<void> {
    const { submittingMediaObjectsStatus, appDataPath } = this.props;

    for (let i = 0; i < submittingMediaObjectsStatus.length; i++) {
      const mediaStatus = submittingMediaObjectsStatus[i];
      if (mediaStatus.submittingMediaObjectStatus === httpStatus.SUCCESS) {
        await deleteFileOnFs(mediaStatus.mediaId, appDataPath, EXAM_FILES);
      }
    }
  }

  // Triggered to redirect the student to the homepage
  backToHomepage(): void {
    const { isTraining } = this.state;
    const {
      currentExam,
      studentId,
      navigation,
      authorizationToken,
      clearTimer,
      clearExamTaking,
      putHasTakingTraining,
      clearStudentPaper,
      endExamTaking,
      currentUser
    } = this.props;

    // Cleaning the exam info from the store
    clearExamTaking(currentExam?.id);
    clearTimer(currentExam?.id || "");

    if (isTraining) {
      // In case of training, notifying the API the user has taken the training
      putHasTakingTraining(
        authorizationToken || "",
        studentId,
        true,
        moment().format("DD-MM-YYYYThh:mm:ssZ")
      );

      clearStudentPaper(currentExam?.id ?? "", currentUser.id);
    }

    // Notifying the main process to end exam mode (window, securities, etc.)
    ipcRenderer.send("QUIT_EXAM", currentExam?.examParams);
    endExamTaking();
    ipcRenderer.send("RESUME_UPDATE_SERVICE");
    removeAsyncStorageItem(CACHED_PROCTORNB_KEY);

    if (
      window &&
      window.navigator.userAgent.includes("SEB") &&
      currentExam?.id !== TRAINING
    ) {
      Linking.openURL(SEB_QUITTING_LINK);
    } else {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "Main" as any
          }
        ]
      });
    }
  }

  confirmProctorStudentConnected(): void {
    const {
      reconnection,
      studentId,
      proctoringRoom,
      currentExam,
      sendProctoringConnectionStatus
    } = this.props;

    if (currentExam && currentExam.id && proctoringRoom) {
      sendProctoringConnectionStatus(
        reconnection,
        currentExam?.id,
        proctoringRoom,
        studentId,
        moment().utc().format()
      );
    }
  }

  render(): JSX.Element {
    const {
      currentStudentPaper,
      displayConfirmModal,
      isStudentPaperReady,
      displaySyncModal,
      displayAntiCheatModal,
      displayForcedNavigationModal,
      displayRemainingDurationWarningModal,
      displayProctoringModal,
      typeOfChangeQuestion,
      allowConfirmModalDismiss,
      isTraining,
      enableProctoring,
      showExam,
      forceStopTimers,
      attachedFiles,
      processCheating,
      isProctoringLive,
      proctoringStopExam,
      stopProctoringAudioRecording,
      isWebFullScreen,
      showModalFullScreen,
      proctorArchiveSent,
      navigateToNext
    } = this.state;
    const {
      submittingStudentPaperStatus,
      studentId,
      currentExam,
      currentUser,
      isOnline,
      appDataPath,
      authorizationToken,
      proctoringExamPause,
      proctorIdentity,
      proctorAudioEnable,
      proctoringRoom,
      twilioAccessToken,
      setExamCursor,
      navigation,
      getSyncExam,
      modifyBookMarked,
      fillStudentPaper,
      initStudentPaperSubmission,
      initUploadMediaObjectOnAWS,
      localApiError
    } = this.props;

    const currentStudentPaperStatus = submittingStudentPaperStatus.find(
      (studentPaper) => currentExam && studentPaper.examId === currentExam.id
    );

    return (
      <Fullscreen
        isEnter={isWebFullScreen}
        onChange={(change: boolean) => this.onFullscreenChange(change)}
      >
        <View style={[styles.container]}>
          {isStudentPaperReady && showExam ? (
            <View style={[styles.containerChild]}>
              <ExamTakingHeader
                enable={false}
                onClickLogo={() => this.backToHomepage()}
                phoneNumberSupport={
                  currentUser.currentEstablishment?.id &&
                  PHONE_NUMBERS[currentUser.currentEstablishment?.id]
                    ? PHONE_NUMBERS[currentUser.currentEstablishment?.id]
                    : PHONE_NUMBERS.default
                }
                showPhoneNumber={currentExam?.examParams?.proctoring}
                remoteExam={currentExam?.examParams?.remoteExam ?? false}
                // duration={currentExam?.remainingDuration}
                navigation={navigation}
                onFinishedTimer={() => undefined}
                onExamValidated={() =>
                  this.setState({
                    displayConfirmModal: true
                  })
                }
                examId={currentExam?.id}
              />
              {currentExam?.examParams?.proctoring &&
                !IS_PREVIEW &&
                enableProctoring && (
                  <ProctorHandler
                    authorizationToken={authorizationToken}
                    studentId={studentId}
                    examId={currentExam.id}
                    appDataPath={appDataPath}
                    isOnline={isOnline}
                    audio={currentExam.examParams.proctoringLiveRecord === true}
                    stopRecordingAudio={
                      stopProctoringAudioRecording || displaySyncModal
                    }
                    initUploadMediaObjectOnAWS={initUploadMediaObjectOnAWS}
                    localApiError={localApiError}
                  />
                )}
              {isProctoringLive &&
                proctoringRoom &&
                twilioAccessToken &&
                !IS_PREVIEW && (
                  <ProctoringLiveHandler
                    studentId={studentId}
                    twilioAccessToken={twilioAccessToken}
                    roomId={proctoringRoom}
                    shouldEnableProctorAudio={proctorAudioEnable}
                    proctorIdentity={proctorIdentity}
                    confirmProctorStudentConnected={
                      this.confirmProctorStudentConnected
                    }
                  />
                )}
              {!displaySyncModal ? (
                <View style={[styles.containerChild]}>
                  <ExamTakingBody
                    currentUserId={currentUser.id}
                    setExamCursor={setExamCursor}
                    token={authorizationToken || ""}
                    appDataPath={appDataPath}
                    currentAttachedFiles={attachedFiles}
                    editAttachedFileUri={this.editAttachedFileUri}
                    currentExam={currentExam}
                    currentStudentPaper={currentStudentPaper}
                    forceStopTimers={forceStopTimers}
                    shouldStopRecording={
                      displaySyncModal ||
                      displayAntiCheatModal ||
                      displayForcedNavigationModal ||
                      displayRemainingDurationWarningModal ||
                      displayConfirmModal
                    }
                    onEndExam={displayConfirmModal}
                    stopSavingStudentAnswers={
                      displayConfirmModal && !allowConfirmModalDismiss
                    }
                    toggleAudioProctoring={this.toggleAudioProctoring}
                    fillStudentPaper={fillStudentPaper}
                    modifyBookMarked={modifyBookMarked}
                    uploadMediaObjects={(
                      medias: StudentAnswerType[],
                      isLastQuestion: boolean
                    ) => this.onUploadMediaObjects(medias, isLastQuestion)}
                    navigateToHomepage={() => this.backToHomepage()}
                    // updateDuration={updateDuration}
                    localApiError={localApiError}
                    onFinishExam={() => this.toggleConfirmModal(true)}
                    onForceNavigation={(
                      callback: (changeType: ChangeEnum) => void,
                      changeType: ChangeEnum
                    ) =>
                      this.displayForcedNavigationModal(
                        true,
                        changeType,
                        callback
                      )
                    }
                    onRemainingDurationWarning={(
                      callback: (changeType: ChangeEnum) => void,
                      changeType: ChangeEnum
                    ) =>
                      this.toggleRemainingDurationWarningModal(
                        true,
                        changeType,
                        callback
                      )
                    }
                    remoteExam={currentExam?.examParams?.remoteExam ?? false}
                    remoteExamUrl={currentExam?.remoteExamUrl}
                  />
                  <PauseExamModal isVisible={proctoringExamPause} />
                  <ProctoringExamModal
                    isVisible={displayProctoringModal}
                    askForProctoringRoom={this.askForProctoringRoom}
                  />
                  <StopExamModal
                    examId={currentExam?.id || ""}
                    isVisible={proctoringStopExam}
                    onPress={() => {
                      this.setState({ forceStopTimers: true });
                      this.finishExam();
                    }}
                  />
                  <FullScreenModal
                    isVisible={showModalFullScreen && !displaySyncModal}
                    onPress={() => this.setState({ isWebFullScreen: true })}
                  />
                  <AntiCheatModal
                    isVisible={
                      !!(displayAntiCheatModal && currentExam?.proctorCode)
                    }
                    publicKey={currentExam?.proctorCode}
                    process={processCheating}
                    onClose={() => this.disableAntiCheat()}
                  />
                  <ExamTakingConfirmModal
                    isVisible={displayConfirmModal}
                    showCloseButton={allowConfirmModalDismiss}
                    onClose={() => {
                      if (allowConfirmModalDismiss) {
                        this.toggleConfirmModal(false);
                        this.forceUpdate();
                      }
                    }}
                    onSend={() => {
                      this.setState({
                        enableProctoring: false,
                        isWebFullScreen: false
                      });
                      this.finishExam();
                    }}
                  />
                  <ExamTakingForcedNavigationModal
                    token={authorizationToken}
                    isVisible={displayForcedNavigationModal}
                    typeOfChangeQuestion={typeOfChangeQuestion}
                    onForceNavigation={() =>
                      navigateToNext(typeOfChangeQuestion)
                    }
                    onAutoToggleModal={() =>
                      this.displayForcedNavigationModal(false)
                    }
                    onClose={() => undefined}
                  />
                  <ExamTakingRemainingDurationWarningModal
                    token={authorizationToken}
                    isVisible={displayRemainingDurationWarningModal}
                    typeOfChangeQuestion={typeOfChangeQuestion}
                    onNavigate={() => navigateToNext(typeOfChangeQuestion)}
                    onClose={() =>
                      this.toggleRemainingDurationWarningModal(false)
                    }
                  />
                </View>
              ) : (
                <ExamTakingSync
                  containerStyle={styles.examSync}
                  requestStatus={
                    isTraining
                      ? httpStatus.SUCCESS
                      : (proctorArchiveSent === null ||
                          proctorArchiveSent === true) &&
                        isOnline &&
                        currentStudentPaperStatus &&
                        currentStudentPaperStatus.submittingStudentPaperStatus !==
                          undefined
                      ? currentStudentPaperStatus.submittingStudentPaperStatus
                      : httpStatus.FAIL
                  }
                  navigateToHomepage={() => {
                    if (authorizationToken && currentExam?.id && isOnline)
                      getSyncExam(authorizationToken, currentExam?.id);
                    this.endStudentPaperSubmission();
                  }}
                  retry={() => {
                    if (currentStudentPaper)
                      initStudentPaperSubmission(true, currentStudentPaper);
                  }}
                />
              )}
            </View>
          ) : (
            <View style={styles.loaderContainer}>
              <Loader
                iconStyle={{
                  width: 65,
                  height: 65
                }}
              />
            </View>
          )}
        </View>
      </Fullscreen>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: "100%"
  },
  containerChild: {
    height: "100%"
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  examSync: {
    marginTop: PADDING_SIDES,
    maxWidth: "50%"
  }
});

export default connect(
  (state: RootState) => {
    return {
      currentUser: state.user
    };
  },
  () => {
    return {};
  }
)(ExamTakingView);
