/* eslint-disable prettier/prettier */
import logoTestWe from "../assets/logo/logotype.png";
import logoTestWeBleu from "../assets/logo/logotype-bleu.svg";

// Icons
import download from "../assets/icons/download.png";
import playIcon from "../assets/icons/play.png";
import crossIcon from "../assets/icons/cross.png";
import wifi from "../assets/icons/wifi.png";
import nowifi from "../assets/icons/no-wifi.png";
import viewIcon from "../assets/icons/view.png";
import infoIcon from "../assets/icons/info.png";
import cannotViewIcon from "../assets/icons/cannotView.png";
import logoutIcon from "../assets/icons/logout.png";
import bookIcon from "../assets/icons/livre.png";
import parameterIcon from "../assets/icons/parametre.png";
import planetIcon from "../assets/icons/planete.png";
import userIcon from "../assets/icons/user.png";
import checkIcon from "../assets/icons/coche.png";
import crossRedIcon from "../assets/icons/croix.png";
import sablier from "../assets/icons/sablier.png";
import copie from "../assets/icons/copie.png";
import commentary from "../assets/icons/com.png";
import reloadIcon from "../assets/icons/reload-ui.png";
import alertIcon from "../assets/icons/alerte.png";
import bookmarkFilled from "../assets/icons/bookmark-on.png";
import bookmark from "../assets/icons/bookmark.png";
import checkBlue from "../assets/icons/cocheok.png";
import checkWhite from "../assets/icons/cocheok-white.png";
import checkGreen from "../assets/icons/cocheok-green@3x.png";
import coche from "../assets/icons/coche-seule.png";
import placeholderProfil from "../assets/icons/profil.png";
import illuHomepage1 from "../assets/icons/testwe-illu-source-illu-2.png";
import illuHomepage2 from "../assets/icons/testwe-illu-source-loupe-2.png";
import question from "../assets/icons/question.png";
import loaderColor from "../assets/icons/loader-color.png";
import loaderWhite from "../assets/icons/group.png";
import micro from "../assets/icons/micro.png";
import chevron from "../assets/icons/chevron.png";
import chevronRight from "../assets/icons/chevron-fermer.png";
import chevronLeft from "../assets/icons/chevron-ouvrir.png";
import watch from "../assets/icons/montre.png";
import horse from "../assets/icons/cheval.png";
import calc from "../assets/icons/calculette.png";
import medias from "../assets/icons/media-2.png";
import keyboard from "../assets/icons/clavier.png";
import qrcode from "../assets/icons/qrcode.png";
import arrowRight from "../assets/icons/fleche.png";
import battery from "../assets/icons/batterie.png";
import tel from "../assets/icons/tel.png";
import chrono from "../assets/icons/chrono.png";
import arrowBlueBottom from "../assets/icons/flechebluebottom.png";
import arrowBlueRight from "../assets/icons/flecheblueright.png";
import lock from "../assets/icons/lock.png";
import fullBtr from "../assets/icons/batteryIcons/fullBtr.png";
import largeBtr from "../assets/icons/batteryIcons/80Btr.png";
import smallBtr from "../assets/icons/batteryIcons/20Btr.png";
import emptyBtr from "../assets/icons/batteryIcons/emptyBtr.png";
import sound from "../assets/icons/audio.png";
import idCardTemplate from "../assets/icons/idCardTemplate.svg";
import faceTemplate from "../assets/icons/faceTemplate.svg";
import resume from "../assets/icons/Resume.png";
import outlinePhoto from "../assets/icons/outlinePhoto.png";
import outlineVideo from "../assets/icons/outlineVideo.png";
import networkCheck from "../assets/icons/network-check.png";
import warning from "../assets/icons/warning.png";
import testCamera from "../assets/icons/test-camera.png";
import testMicro from "../assets/icons/test-micro.png";
import testSpeed from "../assets/icons/test-speed.png";
import cableData from "../assets/icons/cable_data_icon.png";
import frontDesk from "../assets/icons/front_desk_icon.png";
import identityCard from "../assets/icons/ididentitycarddriverlicense.png";
import lamp from "../assets/icons/lamp.png";
import permCameraMic from "../assets/icons/perm-camera-mic.png";
import wc from "../assets/icons/wc.png";
import pen from "../assets/icons/pen.png";
import highlighter from "../assets/icons/fa-solid_highlighter.png";
import comment from "../assets/icons/comment.png";
import one from "../assets/icons/one.png";
import two from "../assets/icons/two.png";
import three from "../assets/icons/three.png";
import four from "../assets/icons/four.png";
import five from "../assets/icons/five.png";
import fullscreenIn from "../assets/icons/fullscreenIn.png";
import fullscreenInBlue from "../assets/icons/fullscreenInBlue.png";
import fullscreenOut from "../assets/icons/fullscreenOut.png";
import fullscreenOutBlue from "../assets/icons/fullscreenOutBlue.png";
import establishmentIcon from "../assets/icons/establishment.png";
import downArrow from "../assets/icons/downArrow.png";
import settings from "../assets/icons/settings.svg";
import time from "../assets/icons/time.svg";
import toggle from "../assets/icons/toggle.svg";
import bin from "../assets/icons/bin.png";
import questionMarkBlue from "../assets/icons/question-mark-blue.svg";
import chronoBlue from "../assets/icons/chrono-blue.svg";
import chronoLocked from "../assets/icons/chrono-locked.svg";
import chronoFilledBlue from "../assets/icons/chrono-filled-blue.svg";

// Web
import permissions from "../assets/web/permissions.png";

export { logoTestWe };

export {
  chronoLocked,
  chronoBlue,
  questionMarkBlue,
  chronoFilledBlue,
  wifi,
  nowifi,
  playIcon,
  fullBtr,
  largeBtr,
  smallBtr,
  download,
  emptyBtr,
  placeholderProfil,
  illuHomepage1,
  illuHomepage2,
  crossIcon,
  battery,
  tel,
  viewIcon,
  infoIcon,
  copie,
  checkBlue,
  commentary,
  cannotViewIcon,
  bookmarkFilled,
  bookmark,
  sablier,
  alertIcon,
  logoutIcon,
  bookIcon,
  parameterIcon,
  planetIcon,
  checkIcon,
  crossRedIcon,
  userIcon,
  reloadIcon,
  coche,
  question,
  loaderColor,
  loaderWhite,
  chevron,
  micro,
  checkWhite,
  checkGreen,
  watch,
  horse,
  calc,
  medias,
  keyboard,
  qrcode,
  chevronRight,
  chevronLeft,
  arrowRight,
  chrono,
  arrowBlueBottom,
  arrowBlueRight,
  lock,
  sound,
  idCardTemplate,
  faceTemplate,
  logoTestWeBleu,
  resume,
  outlinePhoto,
  outlineVideo,
  networkCheck,
  warning,
  permissions,
  testCamera,
  testMicro,
  testSpeed,
  cableData,
  frontDesk,
  identityCard,
  lamp,
  permCameraMic,
  wc,
  pen,
  highlighter,
  comment,
  one,
  two,
  three,
  four,
  five,
  fullscreenIn,
  fullscreenInBlue,
  fullscreenOut,
  fullscreenOutBlue,
  establishmentIcon,
  downArrow,
  settings,
  time,
  toggle,
  bin
};
