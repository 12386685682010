import { ipcRenderer } from "electron";
import moment from "moment";
import React from "react";
import {
  View,
  StyleSheet,
  Text,
  TextStyle,
  StyleProp,
  ViewStyle
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  COLOR_BLUE_TESTWE,
  COLOR_RED,
  COLOR_WHITE
} from "../../../static/misc/colors";
import {
  EACH_SECONDS,
  FONTSIZE_14,
  FONT_GILROY_BOLD,
  IS_PREVIEW,
  PADDING_SIDES
} from "../../../static/misc/constants";
import { chronoFilledBlue } from "../../../static/misc/images";
import {
  updateTimer,
  getItemTimer
} from "../../modules/examTaking/actions/timer";
import { TimerType } from "../../modules/examTaking/types/timer";
import i18n from "../../services/i18n";
import { RootState } from "../../store/rootreducer";
import Icon from "./Icon";

interface ExamTakingTimerProps {
  timers: TimerType[] | [];
  examId?: string;
  elementId?: string; // required if timer is for question or exercice
  currentExamPart?: number; // required if timer is on part / exercice or question
  displayZeroHour?: boolean;
  duration?: number;
  running: boolean;
  timerType: TimerTypeEnum;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  onFinish: (timerType: TimerTypeEnum) => void;
  storeUpdateTimer: (
    examId: string,
    remainingDuration: number,
    timerType: TimerTypeEnum,
    itemId?: string,
    currentExamPart?: number
  ) => void;
  iconLetter?: string;
}

interface ExamTakingTimerState {
  timerTheoricalEnd: number;
  hour: number;
  minutes: number;
  seconds: number;
  remainingDuration: number;
}

export enum TimerTypeEnum {
  EXERCISE,
  QUESTION,
  PART,
  EXAM,
  MODAL
}

class ExamTakingTimer extends React.Component<
  ExamTakingTimerProps,
  ExamTakingTimerState
> {
  intervalStartRecord!: ReturnType<typeof setInterval>;

  intervalCheckIntegrity!: ReturnType<typeof setInterval>;

  constructor(props: ExamTakingTimerProps) {
    super(props);

    this.state = {
      timerTheoricalEnd: 0,
      hour: 0,
      minutes: 0,
      seconds: 0,
      remainingDuration: 0
    };
  }

  componentDidMount(): void {
    if (!IS_PREVIEW) {
      this.getTimer();
      this.setTimer();
    }
  }

  componentDidUpdate(prevProps: ExamTakingTimerProps): void {
    const { timers, running, timerType } = this.props;

    if (prevProps.timers !== timers && TimerTypeEnum.MODAL !== timerType) {
      this.getTimer();
    }
    if (running !== prevProps.running && running) {
      // if timer wasnt running we need to reinit theorical end Date to the moment it start running
      const { remainingDuration } = this.state;
      const current = moment.now();
      this.setState({
        timerTheoricalEnd:
          (current - (current % 1000)) / 1000 + remainingDuration
      });
    }
  }

  componentWillUnmount(): void {
    if (!IS_PREVIEW) {
      clearInterval(this.intervalStartRecord);
      clearInterval(this.intervalCheckIntegrity);
    }
  }

  getTimer = (): void => {
    const {
      duration = 0,
      timers,
      timerType,
      examId,
      elementId,
      currentExamPart
    } = this.props;

    let newDuration;
    if (timerType === TimerTypeEnum.MODAL) {
      newDuration = duration;
    } else {
      const currentTimer: TimerType = getItemTimer(timers, {
        timerType,
        examId,
        elementId,
        currentExamPart
      });

      newDuration = currentTimer?.remainingDuration || 0;
    }
    this.setElements(newDuration);
    const current = moment.now();
    this.setState({
      timerTheoricalEnd: (current - (current % 1000)) / 1000 + newDuration
    });
  };

  setElements = (newDuration: number): void => {
    const hour = newDuration >= 60 ? Math.floor(newDuration / 3600) : 0;
    const minutes =
      newDuration >= 60 ? Math.floor((newDuration % 3600) / 60) : 0;
    const seconds =
      newDuration >= 60 ? Math.floor((newDuration % 3600) % 60) : newDuration;
    this.setState({ hour, minutes, seconds, remainingDuration: newDuration });
  };

  setTimer = (): void => {
    this.intervalStartRecord = setInterval(() => {
      this.localUpdateTimer();
    }, EACH_SECONDS);
    this.intervalCheckIntegrity = setInterval(() => {
      this.checkTimerIntegrity();
    }, EACH_SECONDS * 10);
  };

  localUpdateTimer = (): void => {
    const {
      running,
      onFinish,
      storeUpdateTimer,
      timerType,
      examId,
      elementId,
      currentExamPart
    } = this.props;
    let { hour, minutes, seconds, remainingDuration } = this.state;

    if (running) {
      if (remainingDuration === 0) {
        onFinish(timerType);
        clearInterval(this.intervalStartRecord);
        return;
      }
      if (seconds === 0) {
        seconds = 59;
        if (minutes === 0) {
          minutes = 59;
          if (hour > 0) {
            hour -= 1;
          } else {
            hour = 0;
          }
        } else {
          minutes -= 1;
        }
      } else {
        seconds -= 1;
      }
      remainingDuration -= 1;

      this.setState(
        {
          hour,
          minutes,
          seconds,
          remainingDuration
        },
        () => {
          if (storeUpdateTimer !== undefined && examId) {
            storeUpdateTimer(
              examId,
              remainingDuration,
              timerType,
              elementId,
              currentExamPart
            );
          }
          if (seconds === 0 && minutes === 0 && hour === 0) {
            onFinish(timerType);
            clearInterval(this.intervalStartRecord);
          }
        }
      );
    }
  };

  /**
   * When window of application isn't focused, setInterval will not work as intended and timers may be slower
   * (this is due to the functionnality of web browsers)
   * this function is here to check that we do not move to far away from the theoricalTimerEnd
   * If we do we reset all the variables of timer to stay as close as possible to theoricalTimerEnd
   * Given the fact that we check every 10 seconds for a maximum delta of 10 seconds the
   * bigger difference we can have atm is 19 seconds
   */
  checkTimerIntegrity = (): void => {
    const { timerTheoricalEnd, remainingDuration } = this.state;
    const { running, timerType } = this.props;

    if (running && timerType !== TimerTypeEnum.MODAL) {
      const current = moment.now();
      const currentDate = (current - (current % 1000)) / 1000;
      const timerPracticalEnd = currentDate + remainingDuration;
      const delta = Math.abs(timerTheoricalEnd - timerPracticalEnd);
      if (delta >= 10) {
        let newRest = timerTheoricalEnd - currentDate;
        if (newRest < 0) {
          newRest = 0;
        }
        ipcRenderer.send(
          "LOG_INFO",
          `TIMER | Timer was slower than expected (lose focus) it has been reinit to ${newRest} - delta was: ${delta} `
        );
        this.setElements(newRest);
      }
    }
  };

  render(): JSX.Element {
    const {
      displayZeroHour = false,
      containerStyle,
      textStyle,
      running,
      iconLetter
    } = this.props;
    const { hour, minutes, seconds, remainingDuration } = this.state;

    return (
      <View style={[styles.viewStyle, containerStyle]}>
        <View style={styles.iconContainer}>
          <Text style={styles.iconLetter}>{iconLetter}</Text>
          <Icon icon={chronoFilledBlue} iconStyle={styles.iconStyle} />
        </View>
        {remainingDuration < 300 && running ? (
          <Text style={[styles.timerText, textStyle, styles.redTimer]}>
            {(hour === 0 && displayZeroHour) ?? `0${hour}:`}
            {hour > 0 && hour < 10 ? `0${hour}:` : ""}
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </Text>
        ) : (
          <Text style={[styles.timerText, textStyle]}>
            {(hour === 0 && displayZeroHour) ?? `0${hour}:`}
            {hour > 0 &&
              i18n.t("examNavbar.examTakingTimer.hours", {
                hour
              })}
            {minutes > 0 &&
              i18n.t("examNavbar.examTakingTimer.minutes", {
                minutes: minutes < 10 ? `0${minutes}` : minutes
              })}
          </Text>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  viewStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  iconStyle: { width: 32, height: 32 },
  timerText: {
    paddingLeft: PADDING_SIDES * 0.1,
    fontFamily: FONT_GILROY_BOLD,
    fontSize: FONTSIZE_14,
    color: COLOR_BLUE_TESTWE,
    minWidth: 50
  },
  redTimer: {
    color: COLOR_RED
  },
  iconContainer: {
    position: "relative",
    width: 32,
    height: 32
  },
  iconLetter: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    color: COLOR_WHITE
  }
});

export default connect(
  (state: RootState) => {
    return {
      timers: state.timer.timers
    };
  },
  (dispatch: Dispatch) => {
    return {
      ...bindActionCreators(
        {
          storeUpdateTimer: updateTimer
        },
        dispatch
      )
    };
  }
  // FIXME: fix type here
)(ExamTakingTimer as any);
